import React from 'react';

export const TabBar: React.FC<{
  saleType?: string;
  scrollFocusOn: string;
  onClickDetail: () => void;
  onClickEtc: () => void;
}> = (props) => {
  const { scrollFocusOn, onClickDetail, onClickEtc } = props;
  return (
    <div
      className="product-tab-bar"
      style={{ position: 'sticky', top: '56px', backgroundColor: '#ffffff', zIndex: 10 }}>
      <div
        style={{
          display: 'flex',
          height: '48px',
          borderBottom: '1px solid #eff1f4',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          backgroundColor: '#ffffff',
        }}>
        <div
          style={{
            display: 'flex',
            borderBottom: scrollFocusOn === 'detail' ? '3px solid black' : 'none',
            alignItems: 'center',
            justifyContent: 'center',
            height: '48px',
            position: 'relative',
            paddingTop: scrollFocusOn === 'detail' ? 4 : 0,
            cursor: 'pointer',
          }}
          onClick={() => {
            onClickDetail();
          }}>
          <div
            style={{
              color: scrollFocusOn === 'detail' ? '#242424' : '#b9bbbe',
              fontSize: '16px',
              lineHeight: '19px',
              fontWeight: 'bold',
            }}>
            상세정보
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            borderBottom: scrollFocusOn === 'etc' ? '3px solid black' : 'none',
            alignItems: 'center',
            justifyContent: 'center',
            height: '48px',
            paddingTop: scrollFocusOn === 'policy' ? 4 : 0,
            cursor: 'pointer',
          }}
          onClick={() => {
            onClickEtc();
          }}>
          <div
            style={{
              color: scrollFocusOn === 'etc' ? '#242424' : '#b9bbbe',
              fontSize: '16px',
              lineHeight: '19px',
              fontWeight: 'bold',
            }}>
            {props.saleType === 'class' ? '규칙/정책' : '배송/교환/환불'}
          </div>
        </div>
      </div>
    </div>
  );
};
