import {
  TossPaymentsPaymentMethodEN,
  TossPaymentsPaymentMethodKR,
  TOSS_PAYMENTS_CARD_COMPANY_CODE,
} from '@consts/payment';
import { PaymentStatementPayment } from '@models/purchase';

export const getPaymentValues = (props: {
  totalClassTimes: number;
  totalStudentCount: number;
  applyGroupDiscount?: boolean;
  classFeePerTimes: number;
}) => {
  const totalClassTimes = props.totalClassTimes || 0;
  const studentCount = props.totalStudentCount || 0;

  const groupSaleChildrenCount =
    props.applyGroupDiscount && studentCount > 1 ? 10 - (studentCount > 4 ? 4 : studentCount) : 10;
  const discountsRatio = groupSaleChildrenCount / 10;
  const totalAmount = props.classFeePerTimes * totalClassTimes * studentCount;
  const discountedAmount = Math.ceil(totalAmount * discountsRatio);
  const discountAmount = Math.ceil(totalAmount - discountedAmount);

  return {
    totalAmount: totalAmount || 0,
    discountedAmount: discountedAmount || 0,
    discountAmount: discountAmount || 0,
    discountPercent: 100 - discountsRatio * 100,
  };
};

export const getPaymentMethodText = (payment: PaymentStatementPayment) => {
  let paymentMethod = '-';
  const gateway = payment?.gateway || undefined;
  if (gateway && gateway === 'toss') {
    if (payment.method === TossPaymentsPaymentMethodEN.CARD || payment.method === TossPaymentsPaymentMethodKR.CARD) {
      if (payment.result?.data?.card?.issuerCode) {
        paymentMethod = `${TOSS_PAYMENTS_CARD_COMPANY_CODE[`${payment.result.data.card.issuerCode}`]}카드`;
      }
    } else if (
      payment.method === TossPaymentsPaymentMethodEN.EASY_PAY ||
      payment.method === TossPaymentsPaymentMethodKR.EASY_PAY
    ) {
      if (payment.result?.data?.easyPay) {
        const provider = payment.result.data.easyPay.provider;
        if (provider === 'NAVERPAY' || provider === '네이버페이') {
          paymentMethod = '네이버페이';
        } else if (provider === 'KAKAOPAY' || provider === '카카오페이') {
          paymentMethod = '카카오페이';
        } else if (provider === 'TOSSPAY' || provider === '토스페이' || provider === '토스결제') {
          paymentMethod = '토스페이';
        } else {
          paymentMethod = provider;
        }
      }
    } else if (
      payment.method === TossPaymentsPaymentMethodEN.TRANSFER ||
      payment.method === TossPaymentsPaymentMethodKR.TRANSFER
    ) {
      paymentMethod = '계좌이체';
    } else if (
      payment.method === TossPaymentsPaymentMethodEN.VIRTUAL_ACCOUNT ||
      payment.method === TossPaymentsPaymentMethodKR.VIRTUAL_ACCOUNT
    ) {
      paymentMethod = '가상계좌';
    } else if (payment.method === 'point_only') {
      paymentMethod = '포인트결제';
    }
  } else {
    if (payment.method === 'creditPoints') {
      paymentMethod = '포인트결제';
    } else if (payment.method === 'kakaopay') {
      paymentMethod = '카카오페이';
    } else if (payment.method === 'naverpay') {
      paymentMethod = '네이버페이';
    } else if (payment.method === 'card') {
      paymentMethod = '카드결제';
    }
  }
  return paymentMethod;
};
