import moment from 'moment';
import React from 'react';
import CellTitle from './CellTitle';
import { getPaymentMethodText, getPaymentValues } from '@utils/payment';
import { PaymentStatementPayment } from '@models/purchase';

export interface PaymentResultProps {
  classFee?: number;
  totalClassTimes?: number;
  totalStudentCount?: number;
  applyGroupDiscount?: boolean;
  showClassInfo?: boolean;
  payment: PaymentStatementPayment;
}

export const PaymentResult: React.FC<PaymentResultProps> = (props) => {
  const billingAmount = props.payment.billingAmount;
  const date = props.payment.paidAt;

  const classFee = props.classFee || 0;
  const totalClassTimes = props.totalClassTimes || 0;
  const studentCount = props.totalStudentCount || 0;

  const { totalAmount, discountAmount, discountPercent } = getPaymentValues({
    classFeePerTimes: classFee,
    totalClassTimes: totalClassTimes,
    totalStudentCount: studentCount,
    applyGroupDiscount: props.applyGroupDiscount,
  });

  const usedPoint = props.payment.point || 0;

  const paymentMethod = getPaymentMethodText(props.payment);

  return (
    <div style={{ padding: '28px 20px' }}>
      <CellTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>결제정보</div>
        </div>
      </CellTitle>
      {props.showClassInfo && (
        <div style={{ paddingBottom: '16px', borderBottom: date ? '1px dashed #E8EAED' : 'none' }}>
          <div style={{ borderLeft: '4px solid #EFF1F4', paddingLeft: '12px', marginTop: '16px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                style={{
                  color: 'var(--Neutral-70, #808387)',
                  fontFamily: 'Pretendard',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                }}>
                <div style={{ height: '22px', marginBottom: '8px' }}>1회 수강료</div>
                <div style={{ height: '22px', marginBottom: '8px' }}>신청 회차</div>
                <div style={{ height: '22px' }}>신청 인원</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  color: 'var(--Neutral-90, #424242)',
                  textAlign: 'right',
                  fontFamily: 'Pretendard',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                }}>
                <div style={{ height: '22px', marginBottom: '8px' }}>{`${classFee.toLocaleString()}원`}</div>
                <div style={{ height: '22px', marginBottom: '8px' }}>{`${totalClassTimes}회`}</div>
                <div style={{ height: '22px' }}>{`${studentCount || 0}명`}</div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              color: 'var(--Primary-95, #424242)',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              marginTop: '16px',
            }}>
            <div>총 금액</div>
            <div
              style={{
                color: 'var(--Neutral-100, #242424)',
                textAlign: 'right',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '22px',
              }}>{`${totalAmount.toLocaleString()}원`}</div>
          </div>
        </div>
      )}
      {date && (
        <div style={{ paddingLeft: '0', marginTop: '16px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{
                color: 'var(--Neutral-70, #808387)',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
              }}>
              <div style={{ height: '22px', marginBottom: '8px' }}>결제일시</div>
              <div style={{ height: '22px', marginBottom: '8px' }}>결제수단</div>
              <div style={{ height: '22px' }}>결제금액</div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                color: 'var(--Neutral-90, #424242)',
                textAlign: 'right',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
              }}>
              <div style={{ height: '22px', marginBottom: '8px' }}>{moment(date).format('YYYY.MM.DD. HH:mm:ss')}</div>
              <div style={{ height: '22px', marginBottom: '8px' }}>{paymentMethod}</div>
              <div
                style={{
                  height: '22px',
                  color: 'var(--Neutral-100, #FF3D8F)',
                  textAlign: 'right',
                  fontFamily: 'Pretendard',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                }}>
                {(billingAmount || 0).toLocaleString()}
                <span style={{ fontSize: '16px' }}>원</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {date &&
        (discountAmount > 0 ||
          (usedPoint > 0 && (
            <div style={{ borderLeft: '4px solid #EFF1F4', paddingLeft: '12px', marginTop: '16px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{
                    color: 'var(--Neutral-70, #808387)',
                    fontFamily: 'Pretendard',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '22px',
                  }}>
                  <div style={{ height: '22px', marginBottom: '8px' }}>상품 금액</div>
                  {discountAmount > 0 && (
                    <div style={{ height: '22px', marginBottom: '8px' }}>{`그룹할인(${discountPercent}%)`}</div>
                  )}
                  {usedPoint > 0 && <div style={{ height: '22px' }}>포인트</div>}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    color: 'var(--Neutral-90, #424242)',
                    textAlign: 'right',
                    fontFamily: 'Pretendard',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '22px',
                  }}>
                  <div style={{ height: '22px', marginBottom: '8px' }}>{`${totalAmount.toLocaleString()}원`}</div>
                  {discountAmount > 0 && (
                    <div style={{ height: '22px', marginBottom: '8px' }}>{`-${discountAmount.toLocaleString()}원`}</div>
                  )}
                  {usedPoint > 0 && <div style={{ height: '22px' }}>{`-${usedPoint.toLocaleString()}원`}</div>}
                </div>
              </div>
            </div>
          )))}
    </div>
  );
};

export default PaymentResult;
