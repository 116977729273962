import { ClassCollectionItem, CollectionItem, CommerceCollectionItem } from '@models/class';
import { apiClient } from '.';
import { AxiosResponse } from 'axios';
import { MarketingBannerResponse } from '@models/common';
import { Feed } from '@models/feed';

export const getCommerceFeed = async () => {
  return apiClient.get<CommerceCollectionItem[]>(`/app/v1/feed/commerce`);
};

export const getPurchasedProducts = async () => {
  return apiClient.get(`/app/v1/feed/purchased`);
};

export const getChatBanners = async () => {
  return apiClient.get(`/app/v1/feed/banners/chat`);
};

export const getMyPageBanners = async () => {
  return apiClient.get(`/app/v1/feed/banners/mypage`);
};

export const getClassDetailBanners = async () => {
  return apiClient.get(`/app/v1/feed/banners/class-detail`);
};

export const getPlaceholders = async () => {
  return apiClient.get(`/app/v1/feed/placeholders`);
};

export const getCollectionItem = async (collectionId: string): Promise<AxiosResponse<CollectionItem>> => {
  return apiClient.get(`/app/v1/feed/main/collection/${collectionId}`);
};

export const getClassCollectionItem = async (collectionId: string): Promise<AxiosResponse<ClassCollectionItem>> => {
  return apiClient.get(`/app/v1/feed/main/collection/class/${collectionId}`);
};

export const getCommerceCollectionItem = async (collectionId: string): Promise<AxiosResponse<CollectionItem>> => {
  return apiClient.get(`/app/v1/feed/main/collection/commerce/${collectionId}`);
};

export const getCategories = async () => {
  return apiClient.get(`/app/v1/feed/categories`);
};

export const getPopularKeywords = async () => {
  return apiClient.get<{ _id: string; count: number }[]>(`/app/v1/feed/popular-keywords`);
};

export const getRecommendedKeywords = async () => {
  return apiClient.get(`/app/v1/feed/recommended-keywords`);
};

export const getMarketingBanner = async () => {
  return apiClient.get<MarketingBannerResponse>(`/app/v1/feed/main/marketing-banners`);
};

export const getMainFeed = async () => {
  return apiClient.get<Feed>(`/app/v1/feed/main-feed`);
};

export const getMdPick = async () => {
  return apiClient.get<Feed>(`/app/v1/feed/md-pick`);
};

export const getBanners = async () => {
  return apiClient.get<Feed>(`/app/v1/feed/banners`);
};

export const getChatIntroWarnings = async () => {
  return apiClient.get<Feed>(`/app/v1/feed/chat/intro`);
};

export const getSearchPlaceholder = async () => {
  return apiClient.get<Feed>(`/app/v1/feed/search/placeholder`);
};

export const getSearchPopularKeywords = async () => {
  return apiClient.get<Feed>(`/app/v1/feed/search/popular-keywords`);
};

export const getSearchRecommendKeywords = async () => {
  return apiClient.get<Feed>(`/app/v1/feed/search/recommend-keywords`);
};
