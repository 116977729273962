import '@assets/css/bootstrap-icons.css';
import '@assets/css/bootstrap5.css';
import AppModal from '@components/app/modal/AppInstallModal';
import { useAppSocket } from '@hooks/appSocket';
import { Layout } from 'antd';
import React, { Suspense, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.scss';
import LoadingPage from './pages/Loading';
import routes from './routes';
import { useSessionLogger } from '@hooks/sessionLogger';

const App: React.FC = () => {
  useAppSocket();
  useSessionLogger();

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  const router = createBrowserRouter([...routes]);

  return (
    <HelmetProvider>
      <Suspense fallback={<LoadingPage />}>
        <Layout className="App">
          <Layout.Content>
            <RouterProvider router={router} />
          </Layout.Content>
          <AppModal />
        </Layout>
      </Suspense>
    </HelmetProvider>
  );
};

export default App;
