export enum ProductStatus {
  ALL = 'all',
  OPEN = 'open',
  CLOSE = 'close',
  HIDE = 'hide',
  DENIED = 'denied',
  OPEN_REQUEST = 'open_request',
  EDIT = 'edit',
}

export const PRODUCT_CATEGORIES = [
  {
    categoryId: 'physical',
    categoryName: '신체',
    categorySubList: ['맨몸', '교구', '구기', '발레', '댄스'],
  },
  {
    categoryId: 'artistic',
    categoryName: '예술',
    categorySubList: ['음악', '미술', '퍼포먼스'],
  },
  {
    categoryId: 'scientific',
    categoryName: 'STEAM',
    categorySubList: ['과학', '코딩', '엔지니어링', '브레인', '수학'],
  },
  {
    categoryId: 'lang',
    categoryName: '언어',
    categorySubList: ['한글', '영어', '중국어', '기타 언어'],
  },
  {
    categoryId: 'special',
    categoryName: '특별',
    categorySubList: ['스피치', '요리', '자연', '심리', '성교육', '기타 활동'],
  },
];

export const DEFAULT_CLASS_TEMRS = `수업 예정일 2일 전 17시까지 취소 요청시 전액 환불
환불 가능 기간 경과 후에는 환불 불가
환불 가능 기간 중에는 수업일 변경 요청 가능
환불 가능 기간 경과 후 수업일 연장 요청 1회 가능
수업일 변경 이후로는 환불 불가
정기 다회차 클래스 예약시, 수강 횟수가 1/2를 넘어가면 개인사유로 인한 환불 불가`;
