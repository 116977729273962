import RecommendCaptionImage from '@assets/images/app/caption-recommend@3x.png';
import { ProductModel } from '@models/product';
import React from 'react';

export const ClassRecommendation: React.FC<{ data: ProductModel }> = (props) => {
  const data = props.data;
  const recommendItemList: string[] = (data?.details?.recommendText || '')
    .split('\n')
    .map((line) => {
      return line.trim();
    })
    .filter((line: string) => line.length > 0);

  return (
    <div style={{ marginTop: '80px', paddingLeft: '16px', paddingRight: '16px' }}>
      <img style={{ width: '103px', height: '12px', marginBottom: '10px' }} src={RecommendCaptionImage} />
      <div
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
          lineHeight: '26px',
          letterSpacing: -0.6,
        }}>
        <div style={{ fontWeight: 'bold' }}>이런 친구에게 추천해요</div>
      </div>

      <div style={{ paddingTop: '16px', paddingBottom: '7px' }}>
        {recommendItemList.map((line, index) => {
          if (line[0] === '-') {
            line = line.replace(' - ', '').replace('- ', '').replace('-', '').replace(/^ /, '');
          }

          return (
            <div
              key={`recommend-${index}`}
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '12px',
              }}>
              <div
                style={{
                  display: 'flex',
                  alignSelf: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  backgroundColor: '#242424',
                  flexShrink: 0,
                }}>
                <div
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: '#ffffff',
                  }}>
                  {index + 1}
                </div>
              </div>
              <div
                style={{
                  marginLeft: '12px',
                  border: '1px solid #e8eaed',
                  borderRadius: '8px',
                  width: '100%',
                  padding: '16px',
                }}>
                <div
                  style={{
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#424242',
                  }}>
                  {line}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
