import LiveIcon from '@assets/images/app/icon-live-16@3x.png';
import LocationIcon from '@assets/images/app/icon-location-16@3x.png';
import * as Skeleton from '@components/Skeleton/Skeleton';
import { ProductStatus } from '@consts/products';
import { ProductModel } from '@models/product';
import { getAddressText, getBadges } from '@utils/dataTransform';
import moment from 'moment';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import FavoriteButton from './FavoriteButton';
import CreditCardIcon from '@assets/images/app/card.png';

interface ProductItemProps {
  item?: ProductModel;
  style?: any;
  width?: number;
  onClick?: (event: any) => void;
}

export const ProductItem: React.FC<ProductItemProps> = (props) => {
  const _renderItemImage = (imageUrl: string) => {
    const fixImageUrl = imageUrl;

    const addressText = getAddressText({
      activityType: item?.activityType,
      availableArea: item?.location,
    });

    return (
      <div
        style={{
          width: '100%',
          borderRadius: '8px',
          overflow: 'hidden',
          position: 'relative',
          cursor: 'pointer',
          ...props.style,
        }}>
        {item?.saleType === 'commerce' && item?.closeText && (item?.totalInventory === 0 || item?.soldout) && (
          <div
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '8px',
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              backgroundColor: 'rgba(255,255,255,0.7)',
              zIndex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <div
              style={{
                backgroundColor: '#242424',
                color: 'white',
                fontWeight: 700,
                fontFamily: 'Pretendard',
                fontSize: '12px',
                textAlign: 'center',
                padding: '0 8px',
                height: '24px',
                display: 'flex',
                alignItems: 'center',
              }}>
              {item?.closeText}
            </div>
          </div>
        )}
        <Skeleton.Image
          width="100%"
          src={fixImageUrl}
          aspectRatio={1}
          styles={{ image: { borderRadius: '8px', objectFit: 'cover' } }}
        />
        {props.item?.activityType === 'online' ||
          (addressText && addressText !== '-' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0,0,0,0.6)',
                paddingLeft: '4px',
                paddingRight: '6px',
                height: '20px',
                borderRadius: '4px',
                position: 'absolute',
                top: '8px',
                left: '8px',
              }}>
              <img
                src={props.item?.activityType == 'online' ? LiveIcon : LocationIcon}
                style={{ alignSelf: 'center', width: '14px', height: '14px', marginRight: '2px' }}></img>
              <div
                style={{
                  alignSelf: 'center',
                  fontSize: '11px',
                  color: '#ffffff',
                  fontWeight: 500,
                  lineHeight: '10px',
                }}>
                {addressText == '전체' ? '전체' : addressText}
              </div>
            </div>
          ))}
        <FavoriteButton
          theme="white"
          style={{ position: 'absolute', bottom: '10px', right: '10px' }}
          productId={item?._id}
          saleType={item?.saleType}
        />
      </div>
    );
  };

  const item = props.item;
  const mainImageUrl = item?.thumbnailImage;
  const badges = props && item && item?.badges && item?.badges.length > 0 ? item?.badges : [];

  let isNew = false;
  const firstApprovedAt = props && item && item?.approvedAt ? item?.approvedAt : new Date(1990, 0, 1);
  const monthCheck = moment(new Date()).diff(moment(firstApprovedAt), 'day');
  if (monthCheck <= 30) {
    isNew = true;
  }

  const activeBadgeList = getBadges({
    badges: badges,
    isNew,
    isClosed: item?.status === ProductStatus.CLOSE,
    applyGroupDiscount: item?.applyGroupDiscount,
  });

  return (
    <div id="class-item" style={{ ...props.style, position: 'relative' }} onClick={props.onClick}>
      {mainImageUrl && _renderItemImage(mainImageUrl as string)}
      <div style={{ marginTop: '10px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {props.item?.activityType && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <div
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  color: '#808387',
                  fontWeight: 'bold',
                  marginRight: '4px',
                  lineHeight: '16px',
                }}>
                {props.item.activityType == 'online'
                  ? '비대면'
                  : props.item.activityType == 'studio'
                    ? '스튜디오'
                    : '가정방문'}
              </div>
              <div style={{ width: '1px', height: '8px', backgroundColor: '#d8d8d8', marginRight: 4 }}></div>
              <div
                style={{
                  fontSize: '12px',
                  textAlign: 'left',
                  color: '#808387',
                  fontWeight: 'normal',
                  lineHeight: '16px',
                }}>
                {props.item.sellerName}
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          letterSpacing: -0.2,
          marginTop: '6px',
          flex: 1,
          fontSize: '14px',
          color: '#424242',
          lineHeight: '18px',
          maxHeight: '36px',
          overflow: 'hidden',
        }}>
        <LinesEllipsis text={props.item?.title} maxLine={2} ellipsis="..." trimRight></LinesEllipsis>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          marginTop: '8px',
        }}>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '14px',
            color: '#242424',
            lineHeight: '18px',
          }}>
          {(props.item?.fee || 0).toLocaleString()}원{' '}
          {props.item && props.item.regularTime && props.item.regularTime > 0 ? ` / ${props.item.regularTime}회` : ''}
        </div>
        {item?.discount && item?.discount != '' ? (
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#f03f45',
              lineHeight: '18px',
              marginLeft: '4px',
            }}>
            {item?.discount}
          </div>
        ) : null}
      </div>

      {activeBadgeList.length > 0 && (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '8px' }}>
          {activeBadgeList.map((item, index) => {
            return (
              <div
                key={`badge-${index}`}
                style={{
                  display: 'flex',
                  marginRight: '4px',
                  paddingLeft: '4px',
                  paddingRight: '4px',
                  height: '18px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                  backgroundColor: item.backgroundColor,
                }}>
                <div style={{ fontSize: '11px', color: item.color, fontWeight: 500 }}>{item.value}</div>
              </div>
            );
          })}
        </div>
      )}
      {item?.saleType === 'commerce' && (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
          <img style={{ display: 'inline-block' }} src={CreditCardIcon} />
          <div
            style={{
              color: '#7950EF',
              fontFamily: 'Monoplex KR',
              fontSize: '13px',
              fontStyle: 'italic',
              fontWeight: 700,
              lineHeight: 'normal',
              letterSpacing: '-1px',
            }}>
            바로결제
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductItem;
