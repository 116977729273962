import * as Skeleton from './Skeleton';

const ProductSummaryBundle = () => {
  const widthList = ['50%', '100%', '100%', '75%'];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', padding: '24px 16px' }}>
      {widthList?.map((width, index) => <Skeleton.Base key={`${width}-${index}`} width={width} height={'20px'} />)}
    </div>
  );
};

export default ProductSummaryBundle;
