import { Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import DefaultAvatarBoy from '@assets/images/app/boy.png';
import DefaultAvatarBoyHand from '@assets/images/app/boy-hand.png';
import DefaultAvatarGirl from '@assets/images/app/girl.png';
import DefaultAvatarGirlHand from '@assets/images/app/girl-hand.png';
import { ChildInfo, ChildInfoKeyType } from '@models/auth';

interface EditChildModalProps {
  data?: ChildInfo;
  childList?: ChildInfo[];
  open?: boolean;
  onCancel?: () => void;
  onConfirm?: (info: { mode: string; data: ChildInfo }) => void;
}

export const EditChildModal: React.FC<EditChildModalProps> = (props) => {
  const [childInfo, setChildInfo] = useState<ChildInfo>({});
  const [mode, setMode] = useState<'edit' | 'create'>('create');
  const [title, setTitle] = useState<string>('아이 정보 등록');
  const [confirmButtonText, setConfirmButtonText] = useState<string>('등록하기');

  useEffect(() => {
    if (props.data) {
      setChildInfo(props.data);
      setTitle('아이 정보 수정');
      setConfirmButtonText('수정하기');
      setMode('edit');
    }
  }, [props.data]);

  const onChangeProperty = (key: ChildInfoKeyType, value: any) => {
    setChildInfo((prev) => {
      const newProperties = { ...prev };

      if (key === 'birthday') {
        value = value.slice(0, 8);
      }
      newProperties[key] = value;
      return newProperties;
    });
  };
  return (
    <Modal open={props.open} title={title} footer={false} width={'380px'} onCancel={props.onCancel}>
      <div>
        <div
          style={{
            marginTop: '16px',
            fontSize: '14px',
            color: '#242424',
            fontWeight: 500,
          }}>
          이름
        </div>
        <Input
          style={{
            width: '100%',
            marginTop: '8px',
            color: '#242424',
            fontSize: '16px',
            padding: '12px',
            borderRadius: '12px',
            marginBottom: '20px',
          }}
          placeholder="아이 이름 입력"
          value={childInfo.name}
          onChange={(e) => {
            onChangeProperty('name', e.target.value);
          }}
        />
        <div
          style={{
            fontSize: '14px',
            color: '#242424',
            fontWeight: 500,
          }}>
          생년월일
        </div>
        <Input
          type={'number'}
          style={{
            width: '100%',
            marginTop: '8px',
            color: '#242424',
            fontSize: '16px',
            padding: '16px',
            borderRadius: '12px',
            marginBottom: '20px',
          }}
          value={childInfo.birthday}
          onChange={(e) => {
            onChangeProperty('birthday', e.target.value);
          }}
          placeholder="날짜 입력 (20160101)"
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            alignSelf: 'center',
            backgroundColor: '#FFFFFF',
            alignItems: 'center',
          }}>
          <div
            style={{
              backgroundColor: '#FFFFFF',
              alignItems: 'center',
              marginRight: '60px',
            }}
            onClick={() => {
              onChangeProperty('gender', 'm');
            }}>
            <div
              style={{
                alignItems: 'center',
              }}>
              <div
                style={{
                  width: '52px',
                  height: '52px',
                  borderRadius: '50%',
                  border: childInfo.gender === 'm' ? '3px solid #0c8eff' : 'none',
                }}>
                <img
                  style={{ width: '52px', height: '52px' }}
                  src={childInfo.gender === 'm' ? DefaultAvatarBoyHand : DefaultAvatarBoy}
                />
              </div>

              <div
                style={{
                  fontSize: '15px',
                  color: childInfo.gender === 'm' ? '#0180ef' : '#242424',
                  fontWeight: childInfo.gender === 'm' ? 'bold' : 'normal',
                  marginTop: '8px',
                  textAlign: 'center',
                }}>
                남아
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: '#FFFFFF',
              alignItems: 'center',
            }}
            onClick={() => {
              onChangeProperty('gender', 'f');
            }}>
            <div
              style={{
                alignItems: 'center',
              }}>
              <div
                style={{
                  width: '52px',
                  height: '52px',
                  borderRadius: '50%',
                  border: childInfo.gender === 'f' ? '3px solid #0c8eff' : 'none',
                }}>
                <img
                  style={{ width: '52px', height: '52px' }}
                  src={childInfo.gender === 'f' ? DefaultAvatarGirlHand : DefaultAvatarGirl}
                />
              </div>

              <div
                style={{
                  fontSize: '15px',
                  color: childInfo.gender === 'f' ? '#0180ef' : '#242424',
                  fontWeight: childInfo.gender === 'f' ? 'bold' : 'normal',
                  marginTop: '8px',
                  textAlign: 'center',
                }}>
                여아
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '24px' }}>
          <button
            className="link-button"
            style={{
              border: '1px solid #d8d8d8',
              color: '#d8d8d8',
              backgroundColor: '#ffffff',
              height: '52px',
              width: '150px',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '8px',
              fontWeight: 'bold',
              fontSize: '18px',
            }}
            onClick={() => {
              props.onCancel && props.onCancel();
            }}>
            취소
          </button>
          <button
            className="link-button"
            style={{
              color: '#FFFFFF',
              backgroundColor: '#0c8eff',
              height: '52px',
              width: '150px',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '8px',
              fontWeight: 'bold',
              fontSize: '18px',
            }}
            onClick={() => {
              const children = props.childList;
              const child = childInfo;
              let isExist = false;

              children?.some((item) => {
                if (JSON.stringify(item) === JSON.stringify(child)) {
                  isExist = true;
                  return;
                }
              });

              if (isExist) {
                Modal.error({
                  title: '이미 등록된 아이 정보입니다.',
                  okText: '확인',
                });
                return;
              }

              props.onConfirm && props.onConfirm({ mode, data: childInfo });
            }}>
            {confirmButtonText}
          </button>
        </div>
      </div>
    </Modal>
  );
};
