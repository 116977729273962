import { Modal } from 'antd';
import React from 'react';
import DaumPostcodeEmbed, { Address } from 'react-daum-postcode';
import { v4 } from 'uuid';

interface DaumPostcodeModalProps {
  open?: boolean;
  onComplete?: (address: Address) => void;
  onCancel?: () => void;
}

export const DaumPostcodeModal: React.FC<DaumPostcodeModalProps> = (props) => {
  const handleComplete = (data: Address) => {
    props.onComplete && props.onComplete(data);
  };

  return (
    <Modal
      key={v4()}
      open={props.open}
      maskClosable={true}
      onCancel={props.onCancel}
      footer={null}
      styles={{ body: { paddingTop: '24px', height: 'calc(100dvh - 200px)' } }}>
      {props.open && <DaumPostcodeEmbed style={{ width: '100%', height: '100%' }} onComplete={handleComplete} />}
    </Modal>
  );
};
