import { Carousel } from '@components/common/Carousel';
import * as Skeleton from '@components/Skeleton/Skeleton';
import { cdnImageUrl } from '@utils/image';
import React, { useState } from 'react';

export const ProductDetailImages: React.FC<{ imageUrls?: string[]; closed?: boolean }> = (props) => {
  const [currentPage, setCurrenPage] = useState<number>(1);
  const { imageUrls } = props;

  if (!imageUrls) {
    return <Skeleton.Base style={{ width: '100%', aspectRatio: 1 }} />;
  }

  return (
    <div>
      <div
        style={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          aspectRatio: 1,
          position: 'relative',
        }}>
        <Carousel
          options={{
            align: 'start',
            containScroll: 'trimSnaps',
          }}
          onChangeSlide={(currentSlide: number) => {
            setCurrenPage(currentSlide + 1);
          }}>
          {imageUrls.map((imageUrl: string, index: number) => {
            return (
              <img
                key={`product-image-${index}`}
                width={'100%'}
                src={cdnImageUrl(imageUrl as string)}
                style={{ flex: '0 0 auto', objectFit: 'cover', aspectRatio: 1 }}
              />
            );
          })}
        </Carousel>
        {imageUrls.length > 1 && (
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              bottom: '12px',
              left: '50%',
              transform: 'translateX(-50%)',
              backgroundColor: 'rgba(0,0,0,.6)',
              height: '24px',
              borderRadius: '12px',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6px 10px',
            }}>
            <div
              style={{
                alignSelf: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'var(--White-100, #FFF)',
                fontFamily: 'Pretendard',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '100%',
              }}>
              <span style={{ alignSelf: 'center' }}>{currentPage}</span>
              <span style={{ alignSelf: 'center', width: '8px', textAlign: 'center' }}>/</span>
              <span style={{ color: 'rgba(255, 255, 255, 0.6)', alignSelf: 'center' }}>{imageUrls.length}</span>
            </div>
          </div>
        )}
      </div>
      {props.closed && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            background: 'var(--Black-50, rgba(0, 0, 0, 0.50))',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '20px',
            fontFamily: 'Pretendard',
            fontWeight: 700,
            aspectRatio: 1,
          }}>
          일시품절
        </div>
      )}
    </div>
  );
};
