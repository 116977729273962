import * as chatApi from '@apis/app-chats';
import * as api from '@apis/app-products';
import * as userApi from '@apis/app-user';
import { ReactComponent as FavoriteIcon } from '@assets/images/app/favorite-icon.svg';
import { ReactComponent as FavoritedIcon } from '@assets/images/app/favorited-icon.svg';
import { ReactComponent as ShareIcon } from '@assets/images/app/share-icon.svg';
import { HomeIcon, NavBackIcon } from '@components/app/common/Icons';
import ShareButton from '@components/app/common/ShareButton';
import AppServiceCautionsModal from '@components/app/modal/AppServiceCautionsModal';
import { ClassInquiryModal } from '@components/app/modal/ClassInquiryModal';
import { CommerceOptionModal } from '@components/app/modal/CommerceOptionModal';
import { CommercePaymentModal } from '@components/app/modal/CommercePaymentModal';
import { RequestReopenAlarmModal } from '@components/app/modal/RequestReopenAlarmModal';
import { ClassCaution, ClassCautionBeforeApply } from '@components/app/product-detail/ClassCaution';
import { ClassInformation } from '@components/app/product-detail/ClassInformation';
import { ClassIntroduction } from '@components/app/product-detail/ClassIntroduction';
import { ClassPreparations } from '@components/app/product-detail/ClassPreparations';
import { ClassPreview } from '@components/app/product-detail/ClassPreview';
import { ClassRecommendation } from '@components/app/product-detail/ClassRecommendation';
import { CommerceDetailInformation } from '@components/app/product-detail/CommerceDetailInformation';
import { CommerceInformation } from '@components/app/product-detail/CommerceInformation';
import { Divider, DividerBold } from '@components/app/product-detail/Divider';
import { ProductDetailFooter } from '@components/app/product-detail/ProductDetailFooter';
import { ProductDetailImages } from '@components/app/product-detail/ProductDetailImages';
import { ProductSummary } from '@components/app/product-detail/ProductSummary';
import {
  RecommendedProducts,
  RecommendedProductsByUserCategories,
} from '@components/app/product-detail/RecommendedProducts';
import { SellerReviewSummary } from '@components/app/product-detail/SellerReviewSummary';
import { ShippingInfo } from '@components/app/product-detail/ShippingInfo';
import { TabBar } from '@components/app/product-detail/Tabbar';
import { TutorInformation } from '@components/app/product-detail/TutorInformation';
import ProductSummaryBundle from '@components/Skeleton/ProductSummaryBundle';
import { ProductStatus } from '@consts/products';
import { Roles } from '@consts/role';
import { HEADER_HEIGHT } from '@consts/ui';
import { useAppNavigation } from '@hooks/appNavigation';
import { useAppSocket } from '@hooks/appSocket';
import { useAuthentication } from '@hooks/authentication';
import { usePayment } from '@hooks/payment';
import { useUserFavorites } from '@hooks/userFavorites';
import { ProductSelectedOption } from '@models/commerce';
import { useAppLoginModal } from '@stores/loginModalStore';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getNativeAppInfoFromUserAgent, share } from '@utils/common';
import { APP_BASE_PATH, KAKAO_PARENT_CENTER_URL } from '@variables';
import VisibilitySensor from '@zelty/react-visibility-sensor';
import { OverlayScrollbars } from 'overlayscrollbars';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import * as AppLayout from '../AppLayout';
import { ClassRefundPolicy, CommerceRefundPolicy } from '@components/app/product-detail/RefundPolicy';
import BoxImage from '@assets/images/app/box-image.png';
import { OpenInAppBanner } from '@components/app/common/OpenInAppBanner';
import { OpenInAppModal } from '@components/app/modal/OpenInAppModal';
import { getShowOpenInAppModal } from '@utils/string';
import { isMobile } from 'react-device-detect';

export const ProductPage: React.FC = () => {
  const appSocket = useAppSocket();
  const appNavigation = useAppNavigation();
  const okCancelDialog = useOkCancelDialog();
  const { productId } = useParams();
  const { pathname } = useLocation();
  const { authStore, checkLogin } = useAuthentication();
  const payment = usePayment();
  const myFavorite = useUserFavorites();
  const [headerOnImage, setHeaderOnImage] = useState<boolean>(true);
  const [showRequestChat, setShowRequestChat] = useState<boolean>(false);
  const [showRequestReopenAlarm, setShowRequestReopenAlarm] = useState<boolean>(false);
  const [scrollFocusOn, setScrollFocusOn] = useState<string>('detail');
  const [showOptionModal, setShowOptionModal] = useState<boolean>(false);
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
  const [totalFee, setTotalFee] = useState<number>(0);
  const [selectedOptions, setSelectedOptions] = useState<ProductSelectedOption>({ options: [] });
  const [showCautions, setShowCautions] = useState<boolean>(false);
  const [showOpenInAppBanner, setShowOpenInAppBanner] = useState<boolean>(true);
  const [showOpenInAppModal, setShowOpenInAppModal] = useState<boolean>(false);
  const productDetailInfoRef = useRef<any>();
  const productEtcInfoRef = useRef<any>();
  const scrollTopRef = useRef<any>();
  const loginModal = useAppLoginModal();
  const { isNativeApp } = getNativeAppInfoFromUserAgent();

  useEffect(() => {
    if (payment.status === 'done') {
      setShowPaymentModal(true);
    }
  }, [payment.status]);

  useEffect(() => {
    if (scrollTopRef.current) {
      scrollTopRef.current.scrollIntoView({ block: 'start' });
    }
  }, [pathname]);

  const productItemQuery = useQuery({
    queryKey: [`product-item-${productId}`],
    queryFn: () => api.getProduct(productId!),
    enabled: !!productId,
  });

  const data = productItemQuery.data?.data;

  useEffect(() => {
    document.title = data?.title ? `아이고고 | ${data.title}` : `아이고고 | ${productId}`;
    return () => {
      document.title = '아이고고 - 우리아이 맞춤 키즈클래스 플랫폼';
    };
  }, [data]);

  useEffect(() => {
    setShowOpenInAppModal(true);
  }, []);

  const sellerId = data?.sellerId;
  const isTutorLogin = authStore.user?.role === Roles.TUTOR;
  const parentId = authStore.user?.id;

  const sellerUserQuery = useQuery({
    queryKey: [`user-${sellerId}`],
    queryFn: () => userApi.getUser(sellerId!),
    enabled: !!sellerId,
  });

  const tutorReviewsQuery = useQuery({
    queryKey: [`seller-reviews-${sellerId}`],
    queryFn: () => userApi.getSellerReviewsWithPagenate(sellerId!),
    enabled: !!sellerId,
  });

  const chatRoomQuery = useQuery({
    queryKey: [`chat-room-${productId}-${sellerId}-${parentId}`],
    queryFn: () => chatApi.getChatRoom(`${productId}_${sellerId}_${parentId}`),
    enabled: !!(productId && sellerId && parentId && !isTutorLogin && data?.saleType === 'class'),
  });

  const createChatRoomMutate = useMutation({
    mutationFn: chatApi.createChatRoom,
    onSuccess: () => {
      appNavigation.to(`${APP_BASE_PATH}chat`, {
        state: {
          productId: productId,
          tutorId: sellerId,
          parentId,
        },
      });
    },
  });

  const createCustomerRequestMutate = useMutation({
    mutationFn: userApi.createCustomerRequest,
    onSuccess: () => {
      setShowRequestReopenAlarm(false);
      okCancelDialog.open({
        title: '요청이 완료 되었습니다.',
        content: '',
        type: 'ok',
        onConfirm: () => {
          close();
        },
      });
    },
  });

  const chatRoom = chatRoomQuery.data?.data;
  const sellerReviewInfo = tutorReviewsQuery.data?.data;

  if (productItemQuery.isSuccess && !data) {
    return (
      <AppLayout.Layout>
        <AppLayout.Header hide={true}></AppLayout.Header>
        <AppLayout.Contents style={{ height: '100dvh', overflow: 'hidden' }}>
          <div
            style={{
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}>
              <img src={BoxImage}></img>
              <div
                style={{
                  marginTop: '16px',
                  color: 'var(--Neutral-100, #242424)',
                  textAlign: 'center',
                  fontFamily: 'Pretendard',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '24px',
                }}>
                상품을 준비 중입니다.
              </div>
              <div
                style={{
                  marginTop: '8px',
                  color: 'var(--Neutral-60, #9B9DA0)',
                  textAlign: 'center',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                }}>
                다음에 다시 찾아주세요.
              </div>
            </div>
            <button
              className="link-button"
              style={{
                flexShrink: 0,
                justifySelf: 'flex-end',
                display: 'flex',
                height: '56px',
                borderRadius: '8px',
                border: '1px solid var(--Neutral-30, #E8EAED)',
                background: 'var(--White-100, #FFF)',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                fontWeight: 'bold',
                fontSize: '16px',
                color: '#424242',
              }}
              onClick={() => {
                appNavigation.back();
              }}>
              이전으로
            </button>
          </div>
        </AppLayout.Contents>
        <AppLayout.Footer hide></AppLayout.Footer>
      </AppLayout.Layout>
    );
  }

  return (
    <AppLayout.Layout>
      <AppServiceCautionsModal
        open={showCautions}
        onCancel={() => {
          setShowCautions(false);
        }}></AppServiceCautionsModal>

      <RequestReopenAlarmModal
        open={showRequestReopenAlarm}
        productId={productId}
        user={authStore.user}
        onCancel={() => {
          setShowRequestReopenAlarm(false);
        }}
        onConfirm={(value) => {
          createCustomerRequestMutate.mutate(value);
        }}></RequestReopenAlarmModal>
      <ClassInquiryModal
        childList={authStore.user?.profile?.children}
        open={showRequestChat}
        onCancel={() => {
          setShowRequestChat(false);
        }}
        onConfirm={(inquiryInfo) => {
          const sellerId = data?.sellerId;
          const parentId = authStore.user?.id;

          const address = data?.location?.specificAddress
            ? data.location.specificAddress.address
            : authStore.user?.profile?.address?.address;

          setShowRequestChat(false);
          if (productId && sellerId && parentId && inquiryInfo) {
            createChatRoomMutate.mutate({
              classId: productId,
              tutorId: sellerId,
              parentId,
              inquiryInfo: { ...inquiryInfo, classTitle: data?.title, address },
            });
          }
        }}></ClassInquiryModal>
      <CommerceOptionModal
        open={showOptionModal}
        product={data}
        onChangeTotalFee={(value) => {
          setTotalFee(value);
        }}
        onChangeSelectedOptions={(options) => {
          setSelectedOptions(options);
        }}
        onCancel={() => {
          setShowOptionModal(false);
        }}
        onConfirm={() => {
          payment.clear();
          setShowOptionModal(false);
          setShowPaymentModal(true);
          appSocket.sendEvent({
            type: 'click',
            data: {
              userId: authStore.user?.id,
              path: location.pathname,
              actionData: {
                from: location.pathname,
                productId: data?._id,
                id: 'commerce-option-modal-confirm',
                actionType: 'purchase',
              },
            },
          });
        }}></CommerceOptionModal>
      <CommercePaymentModal
        open={showPaymentModal}
        selectedOptions={selectedOptions}
        onClickBack={() => {
          setShowPaymentModal(false);
          setShowOptionModal(true);
        }}
        onConfirm={() => {
          setShowPaymentModal(false);
        }}
        product={data}
        totalFee={totalFee}></CommercePaymentModal>
      <OpenInAppModal
        open={showOpenInAppModal && isMobile && !isNativeApp && getShowOpenInAppModal()}
        onClose={() => setShowOpenInAppModal(false)}
        currentPath={location.pathname.replace('/', '')}
      />
      <AppLayout.Header
        style={{
          position: 'sticky',
          zIndex: 1,
          top: 0,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          height: HEADER_HEIGHT,
          backgroundColor: headerOnImage ? 'transparent' : '#ffffff',
          borderBottom: headerOnImage ? 'none' : '1px solid #eff1f4',
        }}
        leftButton={
          <div style={{ marginLeft: '20px' }}>
            <button
              className="link-button"
              onClick={() => {
                appNavigation.back();
              }}>
              <NavBackIcon
                color={headerOnImage ? 'white' : '#242424'}
                style={{ filter: headerOnImage ? 'drop-shadow( 0 1px 3px rgba(0, 0, 0, .4))' : 'none' }}
              />
            </button>
            <button
              style={{ marginLeft: '24px' }}
              className="home-button"
              onClick={() => {
                appNavigation.to(`${APP_BASE_PATH}`);
              }}>
              <HomeIcon
                color={headerOnImage ? 'white' : '#242424'}
                style={{ filter: headerOnImage ? 'drop-shadow( 0 1px 3px rgba(0, 0, 0, .4))' : 'none' }}
              />
            </button>
          </div>
        }
        rightButton={
          <ShareButton
            id={'product-share-nav-right'}
            iconColor={headerOnImage ? 'white' : '#242424'}
            iconStyle={{ filter: headerOnImage ? 'drop-shadow( 0 1px 3px rgba(0, 0, 0, .4))' : 'none' }}
            shareOptions={{ title: data?.title, id: data?._id }}
          />
        }></AppLayout.Header>
      <AppLayout.Contents style={{ marginTop: '-56px', height: '100dvh', overflow: 'hidden' }}>
        {showOpenInAppBanner && isMobile && !isNativeApp && (
          <OpenInAppBanner
            currentPath={location.pathname.replace('/', '')}
            onClose={() => {
              setShowOpenInAppBanner(false);
            }}
          />
        )}
        <OverlayScrollbarsComponent
          defer
          options={{
            scrollbars: { autoHide: 'scroll' },
            overflow: {
              x: 'hidden',
            },
          }}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 0,
            position: 'relative',
            height: 'calc(100% - 84px)',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
          events={{
            scroll: (instance: OverlayScrollbars, event: any) => {
              const scrollTop = event.target?.scrollTop;
              if (instance) {
                if (scrollTop > 505) {
                  setHeaderOnImage(false);
                } else {
                  setHeaderOnImage(true);
                }
              }
            },
          }}>
          <div className="class-detail-container" style={{ paddingBottom: '40px' }}>
            <div ref={scrollTopRef}></div>
            <ProductDetailImages
              imageUrls={data?.detailImages as string[]}
              closed={data?.status === ProductStatus.CLOSE || data?.soldout}></ProductDetailImages>
            {productItemQuery.isLoading && <ProductSummaryBundle />}
            {data && <ProductSummary data={data} closed={data?.status === ProductStatus.CLOSE || data?.soldout} />}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                borderTop: '1px solid #EFF1F4',
              }}>
              <button
                className="link-button"
                style={{ height: '60px', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  share({ title: data?.title, id: data?._id });
                  appSocket.sendEvent({
                    type: 'click',
                    data: {
                      userId: authStore.user?.id,
                      path: location.pathname,
                      actionData: {
                        from: location.pathname,
                        actionType: 'share',
                        productId: data?._id,
                        id: 'product-share-summary',
                      },
                    },
                  });
                }}>
                <ShareIcon />
                <span
                  style={{
                    marginLeft: '4px',
                    color: 'var(--Neutral-100, #242424)',
                    textAlign: 'right',
                    fontFamily: 'Pretendard',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                  }}>
                  공유
                </span>
              </button>
              <div style={{ width: '1px', height: '20px', backgroundColor: '#EFF1F4', flexShrink: 0 }}></div>
              <button
                className="link-button"
                style={{ height: '60px', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (!authStore.user) {
                    loginModal.open();
                    return;
                  }

                  if (data?._id && myFavorite.isFavoriteProduct(data?._id)) {
                    myFavorite.removeMyFavorite(data?._id, {
                      onSuccess: () => {
                        productItemQuery.refetch();
                      },
                    });
                    appSocket.sendEvent({
                      type: 'click',
                      data: {
                        userId: authStore.user?.id,
                        path: location.pathname,
                        actionData: {
                          from: location.pathname,
                          actionType: 'favorite-remove',
                          productId: data?._id,
                          id: 'product-favorite',
                        },
                      },
                    });
                  } else if (data?._id) {
                    myFavorite.addMyFavorite(
                      { productId: data?._id, saleType: data?.saleType },
                      {
                        onSuccess: () => {
                          productItemQuery.refetch();
                        },
                      },
                    );
                    appSocket.sendEvent({
                      type: 'click',
                      data: {
                        userId: authStore.user?.id,
                        path: location.pathname,
                        actionData: {
                          from: location.pathname,
                          actionType: 'favorite-add',
                          productId: data?._id,
                          id: 'product-favorite',
                        },
                      },
                    });
                  }
                }}>
                {data?._id && myFavorite.isFavoriteProduct(data?._id) ? <FavoritedIcon /> : <FavoriteIcon />}
                <span
                  style={{
                    marginLeft: '4px',
                    color: 'var(--Neutral-100, #242424)',
                    textAlign: 'right',
                    fontFamily: 'Pretendard',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                  }}>
                  찜
                </span>
                {data?.favoritedCount && data?.favoritedCount > 0 ? (
                  <span
                    style={{
                      marginLeft: '4px',
                      color: 'var(--Neutral-60, #9B9DA0)',
                      fontFamily: 'Pretendard',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                    }}>
                    {data?.favoritedCount}
                  </span>
                ) : (
                  <></>
                )}
              </button>
            </div>
            <DividerBold />
            {data && data.saleType === 'class' && <ClassInformation data={data} />}
            {data && data.saleType === 'commerce' && <CommerceInformation data={data} />}
            <DividerBold />
            {data && <TutorInformation data={data} />}
            {productId && sellerReviewInfo?.contents && (
              <SellerReviewSummary productId={productId} reviews={sellerReviewInfo?.contents} />
            )}
            {productId && sellerReviewInfo?.contents && <DividerBold />}
            <TabBar
              saleType={data?.saleType}
              scrollFocusOn={scrollFocusOn}
              onClickDetail={() => {
                if (productDetailInfoRef.current) {
                  productDetailInfoRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
              }}
              onClickEtc={() => {
                if (productEtcInfoRef.current) {
                  productEtcInfoRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start',
                  });
                }
              }}
            />
            <div id="product-detail-area" ref={productDetailInfoRef}>
              {data && data.saleType === 'class' && <ClassCaution />}
              {data && data.saleType === 'commerce' && data?.detailHtml && (
                <CommerceDetailInformation detailHtml={data?.detailHtml} />
              )}
              {data && data.saleType === 'class' && <ClassIntroduction data={data} />}
              {data && data.saleType === 'class' && <ClassRecommendation data={data} />}
              {data && data.saleType === 'class' && <ClassPreview data={data} />}
              {data && data.saleType === 'class' && <ClassPreparations data={data} />}
              <DividerBold style={{ marginTop: '20px' }} />
            </div>
            <VisibilitySensor
              partialVisibility
              onChange={(isVisible: boolean) => {
                setScrollFocusOn(isVisible ? 'etc' : 'detail');
              }}>
              <div id="product-etc-area" ref={productEtcInfoRef}>
                {data && data.saleType === 'class' && <ClassRefundPolicy data={data} />}
                {data && data.saleType === 'class' && <Divider style={{ paddingTop: '20px' }} />}
                {data && data.saleType === 'class' && (
                  <ClassCautionBeforeApply
                    onClick={() => {
                      setShowCautions(true);
                    }}
                  />
                )}
                {data && data.saleType === 'commerce' && data.shippingProduct && <ShippingInfo data={data} />}
                {data && data.saleType === 'commerce' && <Divider />}
                {data && data.saleType === 'commerce' && <CommerceRefundPolicy data={data} />}
                {data && data.saleType === 'commerce' && (
                  <div style={{ padding: '20px' }}>
                    <button
                      className="link-button"
                      style={{
                        borderRadius: '8px',
                        border: '1px solid var(--Neutral-30, #E8EAED)',
                        background: 'var(--White-100, #FFF)',
                        color: 'var(--Neutral-90, #424242)',
                        textAlign: 'center',
                        fontFamily: 'Pretendard',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        height: '44px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                      onClick={async () => {
                        if (await checkLogin(true)) {
                          const seller = sellerUserQuery.data?.data;
                          const mainCustomerService = seller?.profile?.mainCustomerService;
                          let contactUri = seller?.profile?.customerServiceUrl;

                          if (mainCustomerService === 'url') {
                            contactUri =
                              seller?.profile?.customerServiceUrl || `tel:///${seller?.profile?.customerServicePhone}`;
                          } else if (mainCustomerService === 'phone') {
                            contactUri = seller?.profile?.customerServicePhone
                              ? `tel:///${seller?.profile?.customerServicePhone}`
                              : seller?.profile?.customerServiceUrl;
                          } else {
                            contactUri = seller?.profile?.customerServicePhone
                              ? `tel:///${seller?.profile?.customerServicePhone}`
                              : seller?.profile?.customerServiceUrl;
                          }

                          if (!contactUri) {
                            contactUri = KAKAO_PARENT_CENTER_URL;
                          }
                          window.open(contactUri);
                        }
                      }}>
                      판매자 문의
                    </button>
                  </div>
                )}
                <DividerBold style={{ marginTop: '20px' }} />
                <RecommendedProductsByUserCategories />
                {productId && <RecommendedProducts productId={productId} />}
              </div>
            </VisibilitySensor>
          </div>
        </OverlayScrollbarsComponent>
        {data && (
          <ProductDetailFooter
            data={data}
            onClick={async () => {
              payment.clear();
              const result = await checkLogin(true);

              let actionType = 'click';
              if (data.saleType === 'commerce') {
                if (result) {
                  setShowOptionModal(true);
                }
                actionType = 'purchase-option';
              } else {
                if (result) {
                  if (data?.status === ProductStatus.CLOSE) {
                    setShowRequestReopenAlarm(true);
                    actionType = 'request-reopen';
                  } else {
                    if (chatRoom) {
                      actionType = 'chatroom-reopen';
                      appNavigation.to(`${APP_BASE_PATH}chat`, {
                        state: {
                          productId: chatRoom.classId,
                          tutorId: chatRoom.tutorId,
                          parentId: chatRoom.parentId,
                          chatRoomId: chatRoom._id,
                        },
                      });
                    } else {
                      actionType = 'chat-start';
                      setShowRequestChat(true);
                    }
                  }
                }
              }

              appSocket.sendEvent({
                type: 'click',
                data: {
                  userId: authStore.user?.id,
                  path: location.pathname,
                  actionData: {
                    from: location.pathname,
                    userLogin: result,
                    saleType: data.saleType,
                    isClosed: data?.status === ProductStatus.CLOSE,
                    productId: data?._id,
                    id: 'product-detail-footer',
                    actionType: actionType,
                  },
                },
              });
            }}
          />
        )}
      </AppLayout.Contents>
      <AppLayout.Footer hide></AppLayout.Footer>
    </AppLayout.Layout>
  );
};

export default ProductPage;
