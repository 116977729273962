import AboutCaptionImage from '@assets/images/app/caption-about-copy@3x.png';
import QuotesIcon from '@assets/images/app/icon-quotes@3x.png';
import { ProductModel } from '@models/product';
import { cdnImageUrl } from '@utils/image';
import React from 'react';

export const ClassIntroduction: React.FC<{ data: ProductModel }> = (props) => {
  const data = props.data;
  return (
    <div style={{ marginTop: '40px', paddingLeft: '16px', paddingRight: '16px' }}>
      <img style={{ width: '55px', height: '12px', marginBottom: '10px' }} src={AboutCaptionImage} />
      <div
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
          lineHeight: '26px',
          letterSpacing: -0.6,
        }}>
        클래스를 소개합니다
      </div>

      {data?.detailImages && data?.detailImages.length > 0 && (
        <div style={{ marginTop: '24px' }}>
          <img
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'cover',
            }}
            src={cdnImageUrl(data?.detailImages[0] as string)}
          />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          position: 'relative',
          marginTop: '46px',
          marginBottom: '40px',
          borderRadius: '8px',
          paddingTop: '28px',
          paddingBottom: '20px',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingLeft: '20px',
          paddingRight: '20px',
          border: '1px solid #e8eaed',
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '60px',
            backgroundColor: '#ffffff',
            position: 'absolute',
            top: '-8px',
          }}>
          <img style={{ width: '22px', height: '16px' }} src={QuotesIcon} />
        </div>
        <div
          style={{
            display: 'flex',
            fontSize: '18px',
            lineHeight: '28px',
            color: '#424242',
            textAlign: 'center',
            flexWrap: 'wrap',
          }}>
          {data?.title}
        </div>
        <div style={{ fontSize: '14px', color: '#808387', marginTop: '8px' }}>by. {data?.sellerName}</div>
      </div>

      {(data?.details?.introductions || []).map((item: { title?: string; description?: string }, index: number) => {
        return (
          <div
            key={`intro-${index}`}
            style={{
              marginBottom: data?.details?.introductions?.length === index + 1 ? 0 : '40px',
            }}>
            {item.title && item.title != '' && (
              <div
                style={{
                  fontSize: '18px',
                  lineHeight: '24px',
                  color: '#242424',
                  fontWeight: 'bold',
                  marginBottom: '12px',
                }}>
                {item.title}
              </div>
            )}
            {item.description && item.description !== '' && (
              <div
                style={{
                  fontSize: '16px',
                  color: '#424242',
                  lineHeight: '28px',
                  letterSpacing: -0.4,
                }}>
                {item.description.split('\n').map((line, index2) => {
                  if (line && line != '' && line[0] === '-') {
                    line = line.replace('-', '• ');
                  }

                  return (
                    <div key={`description-${index2}`} style={{ whiteSpace: 'pre-wrap' }}>
                      {line}
                      {(item?.description || '').split('\n').length - 1 === index2 ? '' : '\n'}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
