import { CSSProperties, PropsWithChildren, useState } from 'react';
import './Skeleton.scss';
import { clearEmptyKeysFromObject } from '@utils/common';
import { cdnImageUrl } from '@utils/image';

type SkeletonType = 'circle' | 'square' | 'round';

interface SkeletonDefaultProps {
  width?: number | string;
  height?: number | string;
  size?: number | string;

  color?: string;
  type?: SkeletonType;

  isAnimation?: boolean;
  duration?: number;
}
export interface SkeletonProps extends SkeletonDefaultProps {
  style?: CSSProperties;
  delaySkeletonTime?: number;
}

interface SkeletonImageProps {
  width?: number | string;
  height?: number | string;
  skeletonType?: SkeletonType;
  src?: string;
  aspectRatio?: number;
  styles?: {
    skeleton?: CSSProperties;
    image?: CSSProperties;
  };
}

const defaultOptionsToCssProperties = ({
  width,
  height,
  size,
  color,
  type,
  isAnimation,
  duration,
}: SkeletonDefaultProps): CSSProperties & Record<`$${string}`, string> => {
  const style: ReturnType<typeof defaultOptionsToCssProperties> = {};
  const RoundRadius = '4px';
  const CircleRadius = '50%';
  const SquareRadius = '0';

  if (!isAnimation) style['$animationDisplay'] = 'none';
  if (duration) style['$animationDuration'] = `${duration}s`;
  if (color) style['$mainColor'] = color;

  if (width) style.width = width;
  if (height) style.height = height;
  if (size) {
    style.width = size;
    style.height = size;
  }

  switch (type) {
    case 'square':
      style.borderRadius = SquareRadius;
      break;
    case 'circle':
      style.borderRadius = CircleRadius;
      break;
    default:
      style.borderRadius = RoundRadius;
      break;
  }

  return style;
};

export const Base: React.FC<PropsWithChildren<SkeletonProps>> = (props) => {
  const { style: styleProp, children, ...restProps } = props;
  const propsStyleOptions = clearEmptyKeysFromObject(restProps);

  const style = {
    ...defaultOptionsToCssProperties(propsStyleOptions),
    ...styleProp,
  };

  return (
    <div className="skeleton" style={style}>
      {children}
    </div>
  );
};

export const Image: React.FC<SkeletonImageProps> = (props) => {
  const { width, height, skeletonType, src, aspectRatio, styles } = props;
  const [imgLoaded, setImgLoaded] = useState(false);

  return (
    <Base
      width={width}
      height={imgLoaded ? height : undefined}
      type={skeletonType}
      style={{ ...styles?.skeleton, aspectRatio: imgLoaded ? aspectRatio : undefined }}>
      <img
        width={width}
        height={height}
        src={cdnImageUrl(src)}
        style={{ ...styles?.image, aspectRatio }}
        onLoad={() => setImgLoaded(true)}
      />
    </Base>
  );
};
