import ChildIcon from '@assets/images/app/icon-detail-child.png';
import ClockIcon from '@assets/images/app/icon-detail-clock.png';
import HomeIcon from '@assets/images/app/icon-detail-home.png';
import LocationIcon from '@assets/images/app/icon-detail-location.png';
import OfficeIcon from '@assets/images/app/icon-detail-office.png';
import OnlineIcon from '@assets/images/app/icon-detail-online.png';
import { useAppNavigation } from '@hooks/appNavigation';
import { ProductModel } from '@models/product';
import { getAddressesText } from '@utils/dataTransform';
import { APP_BASE_PATH } from '@variables';
import React from 'react';

import { Divider } from './Divider';

export const ClassInformation: React.FC<{ data: ProductModel }> = (props) => {
  const appNavigation = useAppNavigation();
  const data = props.data;

  let activityTypeIcon = OnlineIcon;

  if (data?.activityType === 'online') {
    activityTypeIcon = OnlineIcon;
  } else if (data?.activityType === 'studio') {
    activityTypeIcon = OfficeIcon;
  } else {
    activityTypeIcon = HomeIcon;
  }

  return (
    <div style={{ padding: '28px 16px' }}>
      <div className="product-detail-title">수업 정보</div>
      <div style={{ padding: '20px 0' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <img style={{ width: '24px', height: '24px', marginRight: '16px' }} src={ClockIcon} />
          <div
            style={{
              fontSize: '18px',
              lineHeight: '24px',
              fontWeight: 500,
              color: '#242424',
            }}>
            {data?.classDuration}분
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '16px',
          }}>
          <img style={{ width: '24px', height: '24px', marginRight: '16px' }} src={activityTypeIcon} />
          <div
            style={{
              flex: '1px',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'row',
            }}>
            <div
              style={{
                fontSize: '18px',
                lineHeight: '24px',
                fontWeight: 500,
                color: '#242424',
                flex: 1,
              }}>
              {data?.activityType &&
                (data?.activityType === 'online' ? (
                  <div>비대면</div>
                ) : data?.activityType === 'studio' ? (
                  <div>스튜디오</div>
                ) : (
                  <div>가정방문</div>
                ))}
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: '1px',
          }}>
          <div style={{ marginLeft: '40px' }}>
            <div
              style={{
                color: '#808387',
                fontSize: '14px',
                lineHeight: '21px',
                marginTop: '4px',
              }}>
              {data?.activityType === 'online' ? (
                <div>튜터님과 협의한 시간에 실시간 비대면으로 진행되는 수업입니다.</div>
              ) : data?.activityType === 'studio' ? (
                <div>튜터님이 운영하는 공간으로 방문하는 수업입니다.</div>
              ) : (
                <div>튜터님이 집으로 오시는 방문수업입니다.</div>
              )}
            </div>
          </div>
        </div>

        {data?.activityType !== 'online' && data?.location && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '16px',
              marginBottom: '4px',
              width: '100%',
              alignItems: 'flex-start',
            }}>
            <img style={{ width: '24px', height: '24px', marginRight: '16px' }} src={LocationIcon} />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                flex: '1px',
              }}>
              <div>
                <div
                  style={{
                    fontSize: '18px',
                    lineHeight: '24px',
                    fontWeight: 500,
                    color: '#242424',
                    whiteSpace: 'pre-wrap',
                  }}>
                  {getAddressesText({ availableArea: data?.location })}
                </div>

                <div
                  style={{
                    color: '#808387',
                    fontSize: '14px',
                    lineHeight: '21px',
                    marginTop: '4px',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}>
                  {data?.activityType === 'home'
                    ? '출강 지역은 튜터님의 실시간 출강 상황에 따라 변동될 수 있습니다. 상담을 통해 확인해 보세요.'
                    : '상세주소는 상담시 문의해주세요.'}
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '16px',
          }}>
          <img style={{ width: '24px', height: '24px', marginRight: '16px' }} src={ChildIcon} />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>
              <div
                style={{
                  fontSize: '18px',
                  lineHeight: '24px',
                  fontWeight: 500,
                  color: '#242424',
                }}>
                {`${data?.ageMin}세 ~ ${data?.ageMax}세`}
              </div>
            </div>
          </div>
        </div>
      </div>
      {data?.tags && data?.tags.length > 0 && (
        <>
          <Divider style={{ padding: 0 }} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginTop: '20px',
            }}>
            {data?.tags.slice(0, 2).map((item, index) => {
              return (
                <button
                  className="link-button"
                  key={`tag-${index}`}
                  style={{
                    display: 'flex',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    borderRadius: '6px',
                    backgroundColor: '#eff1f4',
                    height: '28px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '8px',
                  }}
                  onClick={() => {
                    appNavigation.to(`${APP_BASE_PATH}search`, {
                      state: {
                        keyword: item,
                      },
                    });
                  }}>
                  <div
                    style={{
                      fontSize: '14px',
                      color: '#808387',
                      fontWeight: 'bold',
                    }}>
                    {item}
                  </div>
                </button>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
