import React from 'react';
import SyncLoader from 'react-spinners/SyncLoader';

export const PaymentInProgress: React.FC = () => {
  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
      }}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <SyncLoader size={12} color={'#FF3D8F'}></SyncLoader>
        <div
          style={{
            marginTop: '48px',
            color: 'var(--Neutral-100, #242424)',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
          }}>
          결제를 진행중입니다
        </div>
      </div>
    </div>
  );
};

export default PaymentInProgress;
