import React from 'react';

interface SelectWeekDaysProps {
  style?: any;
  label?: string;
  required?: boolean;
  selectedItems?: string[];
  onChange?: (days: string[]) => void;
}

export const SelectWeekDays: React.FC<SelectWeekDaysProps> = (props) => {
  const days = ['월', '화', '수', '목', '금', '토', '일'];
  const selectedDays = props.selectedItems || [];

  return (
    <div style={{ width: '100%', marginLeft: '16px', marginBottom: 0, ...props.style }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '12px',
            alignItems: 'center',
            width: '100%',
          }}>
          <div
            style={{
              color: '#242424',
              fontSize: '14px',
              lineHeight: '17px',
              fontWeight: 'bold',
            }}>
            {props.label ? props.label : '요일 선택'}
          </div>
          <div
            style={{
              color: '#808387',
              fontSize: '14px',
              lineHeight: '17px',
            }}>
            (중복 가능)
          </div>
          {props.required && <div style={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>✱</div>}
        </div>
        <div
          style={{
            width: 'calc(100% - 32px)',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}>
          {days.map((item, index) => {
            return (
              <div
                key={`days-${index}`}
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '44px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: selectedDays.includes(item) ? '#0c8eff' : '#f1f9ff',
                  borderColor: selectedDays.includes(item) ? 'rgba (255 255 255, 0.2)' : '#e0f0ff',
                  borderStyle: 'solid',
                  borderTopWidth: 1,
                  borderBottomWidth: 1,
                  borderLeftWidth: 1,
                  borderRightWidth: index == days.length - 1 ? 1 : 0,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  let newValues = [...selectedDays];
                  if (newValues.includes(item)) {
                    newValues = newValues.filter((cur) => cur !== item);
                  } else {
                    newValues.push(item);
                  }

                  props.onChange && props.onChange(newValues);
                }}>
                <div
                  style={{
                    color: selectedDays.includes(item) ? 'white' : '#424242',
                    fontSize: '13px',
                    lineHeight: '17px',
                    textAlign: 'center',
                  }}>
                  {item}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SelectWeekDays;
