import { ProductStatus } from '@consts/products';
import { ProductModel } from '@models/product';
import { cdnImageUrl } from '@utils/image';
import React from 'react';
import { ReactComponent as BellICon } from '@assets/images/app/bell.svg';

export const ProductDetailFooter: React.FC<{ data: ProductModel; onClick?: () => void }> = (props) => {
  const data = props.data;
  if (data.saleType === 'commerce' && (data?.status === ProductStatus.CLOSE || data.soldout)) {
    return (
      <div
        style={{
          width: '100%',
          position: 'absolute',
          bottom: 0,
          height: '72px',
          backgroundColor: 'white',
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
          padding: '16px',
          borderTop: '1px solid rgba(0,0,0, .04)',
        }}>
        <button
          className="link-button"
          style={{
            flexShrink: 0,
            justifySelf: 'flex-end',
            display: 'flex',
            height: '56px',
            borderRadius: '8px',
            background: 'var(--White-100, #D5D8DD)',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            fontWeight: 'bold',
            fontSize: '16px',
            color: '#FFFFFF',
            gap: '4px',
          }}>
          <div>일시 품절</div>
        </button>
      </div>
    );
  }

  if (data?.status === ProductStatus.CLOSE) {
    return (
      <div
        style={{
          width: '100%',
          position: 'absolute',
          bottom: 0,
          height: '72px',
          backgroundColor: 'white',
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
          padding: '16px',
          borderTop: '1px solid rgba(0,0,0, .04)',
        }}>
        <button
          className="link-button"
          style={{
            flexShrink: 0,
            justifySelf: 'flex-end',
            display: 'flex',
            height: '56px',
            borderRadius: '8px',
            border: '1px solid #FF509A',
            background: 'var(--White-100, #FFF)',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            fontWeight: 'bold',
            fontSize: '16px',
            color: '#FF3D8F',
            gap: '4px',
          }}
          onClick={props.onClick}>
          <BellICon />
          <div>오픈 알림 신청</div>
        </button>
      </div>
    );
  }

  if (data.saleType === 'commerce') {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          paddingLeft: '16px',
          paddingRight: '16px',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#ffffff',
          borderTop: '1px solid #f5f7fb',
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '12px',
            marginBottom: '12px',
            height: '56px',
            backgroundColor: '#FF3D8F',
            borderRadius: '8px',
            paddingLeft: 0,
            paddingRight: 0,
            width: '100%',
            cursor: 'pointer',
          }}
          onClick={props.onClick}>
          <div
            style={{
              color: 'var(--White-100, #FFF)',
              textAlign: 'center',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '22px',
            }}>
            바로 구매하기
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {data?.bottomTextBanner?.show && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '40px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0,0,0,0.9)',
            left: 0,
            right: 0,
            bottom: '72px',
            zIndex: 100,
          }}>
          {data?.bottomTextBanner?.icon && (
            <img
              style={{ width: '20px', height: '20px', marginRight: '4px', opacity: 1 }}
              src={cdnImageUrl(data?.bottomTextBanner?.icon)}
            />
          )}
          {data?.bottomTextBanner?.highlightText && (
            <div
              style={{
                color: '#ff676f',
                fontSize: '15px',
                lineHeight: '20px',
                fontWeight: 'bold',
                opacity: '1px',
                alignSelf: 'center',
                marginRight: '4px',
              }}>
              {data?.bottomTextBanner?.highlightText}
            </div>
          )}
          {data?.bottomTextBanner?.normalText && (
            <div
              style={{
                color: '#ffffff',
                fontSize: '15px',
                lineHeight: '20px',
                fontWeight: '500',
                opacity: '1px',
                alignSelf: 'center',
              }}>
              {data?.bottomTextBanner?.normalText}
            </div>
          )}
        </div>
      )}
      <div
        style={{
          width: '100%',
          position: 'absolute',
          bottom: 0,
          height: '72px',
          backgroundColor: 'white',
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
          padding: '16px',
          borderTop: '1px solid rgba(0,0,0, .04)',
        }}>
        <div
          style={{
            display: 'flex',
            color: 'var(--Neutral-100, #242424)',
            fontFamily: 'Pretendard',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '22px',
          }}>
          {(data?.fee || 0).toLocaleString()}원
          {data?.showRegularTime && data?.regularTime && data?.regularTime > 0 ? ` / ${data?.regularTime}회` : <></>}
        </div>
        <button
          className="link-button"
          style={{
            color: '#FFFFFF',
            backgroundColor: '#FF3D8F',
            height: '56px',
            width: '160px',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '8px',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '22px',
          }}
          onClick={props.onClick}>
          {'상담 후 수강하기'}
        </button>
      </div>
    </div>
  );
};
