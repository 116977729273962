import ArrowRightIcon from '@assets/images/app/icon-arr-dark-right.png';
import ExclamationIcon from '@assets/images/app/icon-exclamation-red.png';
import { openLink } from '@utils/common';
import React from 'react';

export const ClassCaution: React.FC = () => {
  return (
    <div
      style={{
        marginTop: '24px',
        marginBottom: 0,
        marginLeft: '16px',
        marginRight: '16px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '12px',
        backgroundColor: '#fff5f5',
        padding: '24px',
      }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <img style={{ width: '20px', height: '20px', marginRight: '6px' }} src={ExclamationIcon} />
        <div
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#f03f45',
          }}>
          개인거래 주의 안내
        </div>
      </div>
      <div
        style={{
          marginTop: '16px',
          fontSize: '14px',
          lineHeight: '21px',
          color: '#66686b',
          fontWeight: 'bold',
          flexWrap: 'wrap',
          textAlign: 'center',
        }}>
        채팅을 통해
        <span style={{ color: '#f03f45', fontWeight: 'bold' }}>개인번호 요청</span> 및
        <span style={{ color: '#f03f45', fontWeight: 'bold' }}>개인 거래 요구,</span>
        <br />
        <span style={{ color: '#f03f45', fontWeight: 'bold' }}>SNS로 유도</span>
        하는 경우 결제하지 마시고
        <br />
        <span
          style={{
            color: '#0180ef',
            fontWeight: 'bold',
            textDecorationLine: 'underline',
          }}
          onClick={() => {
            openLink('https://pf.kakao.com/_dxjxixaC/chat');
          }}>
          아이고고 고객센터
        </span>
        로 문의해 주시기 바랍니다.
        <br />
        (위반 사례 발견 시, 서비스 이용 제재조치)
      </div>
    </div>
  );
};

export const ClassCautionBeforeApply: React.FC<{ onClick?: () => void }> = (props) => {
  return (
    <div
      style={{
        marginTop: '32px',
        paddingLeft: '16px',
        paddingRight: '16px',
        marginBottom: '40px',
      }}>
      <div
        style={{
          fontSize: '20px',
          fontWeight: 'bold',
          lineHeight: '26px',
          letterSpacing: -0.6,
        }}>
        신청 전 유의사항
      </div>
      <button className="link-button" onClick={props.onClick} style={{ width: '100%', textAlign: 'left' }}>
        <div
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: '#ffffff',
            marginTop: '8px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <div
            style={{
              alignSelf: 'center',
              fontSize: '14px',
              color: '#66686b',
              lineHeight: '21px',
              letterSpacing: -0.4,
              width: '100%',
            }}>
            <span style={{ fontWeight: 'normal' }}>수업료 결제, 스케줄 변경, 환불 정책 </span>
            등의 유의사항을 확인해주세요.
          </div>
          <img src={ArrowRightIcon} style={{ alignSelf: 'center', width: '20px', height: '20px' }} />
        </div>
      </button>
    </div>
  );
};
