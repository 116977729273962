import { RemoteConfig } from '@models/remote-config';
import { create } from 'zustand';

interface AppUpdateModalData {
  type?: 'force' | 'recommend' | 'maintenance';
  description?: string;
  startDate?: string;
  endDate?: string;
}
interface AppUpdateModal {
  show?: boolean;
  data?: RemoteConfig;
  openData?: AppUpdateModalData;
  open: (openData: AppUpdateModalData) => void;
  setConfigData: (data: RemoteConfig) => void;
  close: () => void;
}

export const useAppUpdateModal = create<AppUpdateModal>((set) => ({
  show: false,
  open: (openData: AppUpdateModalData) => {
    set({ show: true, openData });
  },
  close: () => {
    set({ show: false });
  },
  setConfigData: (data: RemoteConfig) => {
    set({ data });
  },
}));
