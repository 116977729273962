import * as productApi from '@apis/app-products';
import * as userApi from '@apis/app-user';
import { useAuthStore } from '@stores/authStore';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

export const useUserFavorites = () => {
  const [favorites, setFavorites] = useState<{ [key: string]: any }>({});
  const authStore = useAuthStore();

  const mutationAddFavorite = useMutation({
    mutationFn: productApi.createMyFavorites,
    onSuccess: async () => {
      const response = await userApi.getFavorite();
      const favoriteProductIds = response.data.map((item) => {
        return item && item._id;
      });
      authStore.setFavorites(favoriteProductIds);
    },
  });

  const mutationRemoveFavorite = useMutation({
    mutationFn: productApi.deleteMyFavorites,
    onSuccess: async () => {
      const response = await userApi.getFavorite();
      const favoriteProductIds = response.data.map((item) => {
        return item && item._id;
      });
      authStore.setFavorites(favoriteProductIds);
    },
  });

  useEffect(() => {
    const dict: { [key: string]: any } = {};
    (authStore.favorites || []).forEach((productId: string) => {
      dict[productId] = 1;
    });
    setFavorites(dict);
  }, [authStore.favorites]);

  const isFavoriteProduct = (productId: string): boolean => {
    return !!favorites[productId];
  };

  return {
    isFavoriteProduct,
    addMyFavorite: mutationAddFavorite.mutate,
    removeMyFavorite: mutationRemoveFavorite.mutate,
    favorites: favorites,
  };
};
