import { NATIVE_APP_SCHEME, SHARE_BASE_URL } from '@variables';
import { toast } from 'react-toastify';
import { MessageActions } from '@hooks/appMessage';
import { User } from '@models/auth';
import { APP_STORE_LINK } from '@consts/url';
import { isMacOs, isWindows } from 'react-device-detect';

export interface ShareOptions {
  title?: string;
  type?: string;
  id?: string;
}

export interface NativeMessage {
  type: MessageActions;
  data: object;
}

export const getNativeAppInfoFromUserAgent = (): {
  isNativeApp: boolean;
  appVersion: string | undefined;
  appPlatform: 'ios' | 'android' | undefined;
} => {
  const isNativeApp = /igogo-react-native-app/i.test(navigator.userAgent);
  let appPlatform: 'ios' | 'android' | undefined = undefined;
  let appVersion: string | undefined = undefined;
  if (isNativeApp) {
    if (navigator.userAgent.endsWith('ios')) {
      appPlatform = 'ios';
    } else if (navigator.userAgent.endsWith('android')) {
      appPlatform = 'android';
    }
    const regex = /igogo-react-native-app\/(\S+)/;
    const match = navigator.userAgent.match(regex);
    if (match) {
      appVersion = match[1];
    }
  }
  return {
    isNativeApp,
    appVersion,
    appPlatform,
  };
};

export const sendMessageToNative = (message: NativeMessage) => {
  return (window as any).ReactNativeWebView?.postMessage(JSON.stringify(message));
};

export const share = (options: ShareOptions) => {
  const { isNativeApp } = getNativeAppInfoFromUserAgent();

  if (options.id) {
    let url = `${SHARE_BASE_URL}/${options.id}`;
    if (options?.type === 'collection') {
      url = `${SHARE_BASE_URL}/c/${options.id}`;
    }
    const productTitle = `[아이고고] ${options.title || ''}`;

    if (isNativeApp) {
      const message = {
        type: MessageActions.SHARE_URL,
        data: {
          message: `${productTitle}`,
          url,
        },
      };
      sendMessageToNative(message);
    } else {
      navigator.clipboard.writeText(url);
      toast.dark('공유 URL을 클립보드에 복사했습니다', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        bodyStyle: {
          color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
          textAlign: 'center',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
        },
        theme: 'dark',
      });
    }
  }
};

export const setPushAlarmAgree = (userData: User | undefined) => {
  if (userData) {
    const isParent = userData.role === 'parent';
    const isPushAgree = userData.profile?.otherAlarmAgree || false;
    let messageType = isParent ? MessageActions.ACCPET_PARENT_ETC_PUSH : MessageActions.ACCPET_TUTOR_ETC_PUSH;
    if (!isPushAgree) {
      messageType = isParent ? MessageActions.DENY_PARENT_ETC_PUSH : MessageActions.DENY_TUTOR_ETC_PUSH;
    }
    const message = {
      type: messageType,
      data: {},
    };
    sendMessageToNative(message);
  } else {
    const message = {
      type: MessageActions.DENY_ETC_PUSH,
      data: {},
    };
    sendMessageToNative(message);
  }
};

export const deleteFilterUndefined = <T>(value: T | undefined): value is T => !!value;

export const clearEmptyKeysFromObject = (obj: object) => {
  const shallowCopyObject = { ...obj };

  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'undefined') delete shallowCopyObject[key as keyof typeof shallowCopyObject];
  }

  return shallowCopyObject;
};

export const downloadImageFilesOnNativeApp = (images: string[]) => {
  const response = {
    type: MessageActions.DOWNLOAD_IMAGES,
    data: {
      images,
    },
  };
  sendMessageToNative(response);
};

export const openLink = (url: string) => {
  const { isNativeApp, appVersion } = getNativeAppInfoFromUserAgent();
  if (isNativeApp && appVersion) {
    const postMessage = {
      type: MessageActions.SEND_INAPP_BROWSER,
      data: {
        url: url,
      },
    };
    sendMessageToNative(postMessage);
  } else {
    window.open(url);
  }
};

export const goToAppStore = () => {
  const { isNativeApp, appPlatform } = getNativeAppInfoFromUserAgent();
  if (isNativeApp && appPlatform) {
    const storeUrl = APP_STORE_LINK[`${appPlatform}`];
    window.location.href = storeUrl;
  }
};

export const getMobileWebPlatform = () => {
  let mobilePlatform: 'android' | 'ios' | undefined = undefined;
  if (navigator.userAgent.match(/Android/i)) {
    mobilePlatform = 'android';
  } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    mobilePlatform = 'ios';
  }
  return mobilePlatform;
};

export const openInNativeApp = (path: string) => {
  const { isNativeApp } = getNativeAppInfoFromUserAgent();
  const mobileWebPlatform = getMobileWebPlatform();
  const appSchemePath = `${NATIVE_APP_SCHEME}${path}`;
  let storePath: string | undefined = undefined;
  if (path && !isNativeApp) {
    if (mobileWebPlatform) {
      storePath = `${APP_STORE_LINK[`${mobileWebPlatform}`]}`;
    } else {
      if (isMacOs) {
        storePath = `${APP_STORE_LINK[`ios`]}`;
      } else if (isWindows) {
        storePath = `${APP_STORE_LINK[`android`]}`;
      }
    }

    window.location.href = appSchemePath;
    if (storePath) {
      setTimeout(() => {
        window.location.href = storePath!;
      }, 200);
    }
  }
};
