import * as authApi from '@apis/auth';
import { Roles } from '@consts/role';
import { ChatMessage } from '@models/chat';
import { useAppSocketStore } from '@stores/appSocketStore';
import { useAppUserSessionStore } from '@stores/appUserSessionStore';
import { useAuthStore } from '@stores/authStore';
import { useNotificationStore } from '@stores/notificationStore';
import { getNativeAppInfoFromUserAgent } from '@utils/common';
import { getUserDeviceId } from '@utils/string';
import { API_URL, APP_BASE_PATH } from '@variables';
import md5 from 'md5';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import * as io from 'socket.io-client';
import { ManagerOptions, Socket } from 'socket.io-client';
import { DeepLinkActions } from './deeplink';

export interface AppSocketPayload {
  roomId?: string;
  sender: {
    userId: string;
    role: Roles;
  };
}

export interface ChatMessagePayload extends AppSocketPayload {
  data: Partial<ChatMessage>;
}

export interface ChatFilesPayloadData extends Partial<ChatMessage> {
  files: Buffer[];
}

export interface ChatFilesPayload extends AppSocketPayload {
  data: ChatFilesPayloadData;
}

export interface ReadMessagePayload extends AppSocketPayload {
  messageId: string;
  classId: string;
  parentId: string;
  tutorId: string;
}

export interface AppEventPayload {
  type: 'user-agent' | 'user-info' | 'login' | 'logout' | 'view' | 'click';
  md5?: string;
  data?: any;
}

export interface AppTokenPayload {
  deviceId: string;
  fcmToken: string;
  iosOriginToken?: string;
  userId?: string;
  lastLoginType?: string;
}

export interface ConnectOptions {
  deviceId: string;
  [key: string]: any;
}

type EventFunctionKeys =
  | 'connect'
  | 'disconnect'
  | 'chat_message'
  | 'read_message'
  | 'payment_result'
  | 'chat_message_status';
export class AppSocket {
  connecting: boolean = false;
  connected: boolean = false;
  host: string;
  path: string;
  socket?: Socket;
  opts?: Partial<ManagerOptions>;
  eventFunctions: { [key: string]: { [key: string]: any } } = {
    connect: {},
    disconnect: {},
    chat_message: {},
    read_message: {},
    payment_result: {},
    chat_message_status: {},
  };

  constructor(host: string, path?: string, opts?: Partial<ManagerOptions>) {
    this.host = host;
    this.opts = opts;

    if (path) {
      this.path = path;
    } else {
      this.path = '/socket.io';
    }
  }

  connect = (options?: ConnectOptions) => {
    this.connecting = true;
    this.socket = io.connect(this.host, {
      path: this.path,
      query: options,
      reconnection: true,
      ...this.opts,
    });

    this.socket.on('connect', () => {
      this.connected = true;
      Object.values(this.eventFunctions['connect']).forEach((func) => func());
    });

    this.socket.on('disconnect', () => {
      this.connected = false;
      Object.values(this.eventFunctions['disconnect']).forEach((func) => func());
    });

    this.socket.on('chat_message', (response: object) => {
      Object.values(this.eventFunctions['chat_message']).forEach((func) => func(response));
    });

    this.socket.on('chat_message_status', (response: object) => {
      Object.values(this.eventFunctions['chat_message_status']).forEach((func) => func(response));
    });

    this.socket.on('read_message', (response: object) => {
      Object.values(this.eventFunctions['read_message']).forEach((func) => func(response));
    });

    this.socket.on('payment_result', (response: object) => {
      Object.values(this.eventFunctions['payment_result']).forEach((func) => func(response, this.socket));
    });
  };

  disconnect = () => {
    if (this.socket && this.socket.connected) {
      this.socket.disconnect();
    } else {
      throw 'There is no connection';
    }
  };

  emit = (event: string, data: object) => {
    if (this.socket) {
      this.socket.emit(event, data);
    } else {
      throw 'There is no connection';
    }
  };

  addSocketEventListener = (event: EventFunctionKeys, key: string, callback: (data: any) => void) => {
    this.eventFunctions[event][key] = callback;
  };

  removeSocketEventListener = (event: EventFunctionKeys, key: string) => {
    try {
      delete this.eventFunctions[event][key];
      // eslint-disable-next-line no-empty
    } catch {}
  };
}

const appSocket = new AppSocket(API_URL, undefined, { transports: ['websocket'] });

export const useAppSocket = () => {
  const appSocketStore = useAppSocketStore();
  const authStore = useAuthStore();
  const notificationStore = useNotificationStore();
  const sessionStore = useAppUserSessionStore();

  useEffect(() => {
    if (!appSocket.connecting) {
      appSocket.connect({ deviceId: getUserDeviceId(), sessionId: sessionStore.getSessionId() });
      appSocketStore.setAppSocket(appSocket);
      appSocket.addSocketEventListener('connect', 'app-connect', () => {
        appSocketStore.setConnected(true);
        sendEvent({ type: 'user-agent', data: { userAgent: navigator.userAgent } }, appSocket);

        authApi
          .getMe()
          .then((response) => {
            authStore.setUser(response.data);
            if (response.data) {
              if (sessionStore.step === 0) {
                sendEvent({ type: 'login', data: authStore.user }, appSocket);
              } else {
                sendEvent({ type: 'user-info', data: response.data }, appSocket);
              }
            }
          })
          .catch(() => {});
        sessionStore.setActionData({ actionType: 'enter', to: window.location.pathname, from: document.referrer });
      });
      appSocket.addSocketEventListener('disconnect', 'app-disconnect', () => {
        appSocketStore.setConnected(false);
      });
      appSocketStore.setInitialized(true);
    }
  }, []);

  useEffect(() => {
    appSocket.addSocketEventListener('chat_message', 'app-socket-chat-message', (data: ChatMessage) => {
      if (window.location.pathname !== `${APP_BASE_PATH}chat` && data.senderId !== authStore.user?.id) {
        notificationStore.addNotification({
          title: '새로운 메시지 알림',
          description: data.image ? '[이미지]' : data.text,
          action: {
            action: DeepLinkActions.GO_TO_CHAT,
            payload: { productId: data.classId, tutorId: data.tutorId, parentId: data.parentId, chatRoomId: data._id },
          },
        });
      }
    });
  }, []);

  const sendMessageRead = (data: ReadMessagePayload) => {
    appSocketStore.appSocket?.emit('read_message', data);
  };

  const sendMessage = (data: ChatMessagePayload) => {
    appSocketStore.appSocket?.emit('chat_message', data);
  };

  const sendImageFiles = (data: ChatFilesPayload) => {
    appSocketStore.appSocket?.emit('chat_image_files', data);
  };

  const enterRoom = (data: AppSocketPayload) => {
    appSocketStore.appSocket?.emit('chat_enter', data);
  };

  const leaveRoom = (data: AppSocketPayload) => {
    appSocketStore.appSocket?.emit('chat_leave', data);
  };

  const sendEvent = (data: AppEventPayload, socket?: AppSocket) => {
    const { isNativeApp } = getNativeAppInfoFromUserAgent();
    const eventData = { ...data, md5: md5(JSON.stringify(data)), isNativeApp, isMobile };
    (socket || appSocketStore.appSocket)?.emit('event', eventData);
  };

  const updateAppToken = (data: AppTokenPayload) => {
    appSocketStore.appSocket?.emit('app_token', data);
  };

  const increasePushNotificationClickCount = (pushNotificationId: string) => {
    appSocketStore.appSocket?.emit('click_push', { pushNotificationId });
  };

  return {
    sendMessage,
    sendImageFiles,
    sendEvent,
    enterRoom,
    leaveRoom,
    sendMessageRead,
    updateAppToken,
    increasePushNotificationClickCount,
    socket: appSocket,
    connected: appSocketStore.connected,
  };
};
