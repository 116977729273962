import { ChildInfo } from '@models/auth';
import { ChildItem } from './ChildItem';

export interface SelectChildrenProps {
  childList?: ChildInfo[];
  selectedItems?: ChildInfo[];
  onChange?: (selectedChildren: ChildInfo[]) => void;
  selectable?: boolean;
  editable?: boolean;
}

export const SelectChildren: React.FC<SelectChildrenProps> = (props) => {
  const selectedChildren = props.selectedItems || [];
  const selectedIds = selectedChildren.map((selected) => new ChildInfo(selected).toString());
  return (
    <div>
      {(props.childList || []).map((child) => {
        const id = new ChildInfo(child).toString();
        return (
          <ChildItem
            selectable={props.selectable}
            editable={props.editable}
            key={id}
            data={child}
            onSelect={(item) => {
              if (!selectedChildren.find((selected) => new ChildInfo(selected).toString() === id)) {
                const newChildren = [...selectedChildren, { id, ...item }];
                props.onChange && props.onChange(newChildren);
              }
            }}
            onDeselect={() => {
              const newChildren = [...selectedChildren];
              const deselectIndex = newChildren.findIndex((selected) => new ChildInfo(selected).toString() === id);

              newChildren.splice(deselectIndex, 1);

              props.onChange && props.onChange(newChildren);
            }}
            selected={selectedIds.includes(id)}></ChildItem>
        );
      })}
    </div>
  );
};
