import { ChatMessage, ChatRoom } from '@models/chat';
import { apiClient } from '.';
import { PagenatedResponse } from '@models/common';
import { ClassInquiryInfo } from '@components/app/modal/ClassInquiryModal';
import { SearchPagenateQuery } from '@stores/chatRoomsStore';

export const getChatRooms = async () => {
  return apiClient.get<ChatRoom[]>(`app/v1/chats`);
};

export const searchChatRooms = async (query?: SearchPagenateQuery) => {
  return apiClient.get<PagenatedResponse<ChatRoom>>(`app/v1/chats/search`, {
    params: query,
  });
};

export const getChatMessages = async (chatRoomId: string, page: number = 0, size: number = 20) => {
  return apiClient.get<PagenatedResponse<ChatMessage>>(`/app/v1/chats/${chatRoomId}/messages`, {
    params: { page, size },
  });
};

export const getChatRoom = async (chatRoomId: string) => {
  return apiClient.get<ChatRoom>(`/app/v1/chats/${chatRoomId}`);
};

export const createChatRoom = async (body: {
  classId: string;
  tutorId: string;
  parentId: string;
  inquiryInfo: ClassInquiryInfo;
}) => {
  return apiClient.post(`/app/v1/chats`, body);
};
