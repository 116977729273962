import * as commonApi from '@apis/common';
import QuotesIcon from '@assets/images/app/icon-quotes@3x.png';
import { Carousel } from '@components/common/Carousel';
import { useAppNavigation } from '@hooks/appNavigation';
import { DeepLinkActions, useDeeplink } from '@hooks/deeplink';
import { ReviewContent } from '@models/review';
import { cdnImageUrl } from '@utils/image';
import React from 'react';

export const SellerReviewSummary: React.FC<{ productId: string; reviews: ReviewContent[] }> = (props) => {
  const { productId, reviews } = props;
  const appNavigation = useAppNavigation();
  const { linkTo } = useDeeplink();

  if (!reviews || reviews.length === 0) {
    return <></>;
  }

  return (
    <div
      style={{
        padding: '16px',
        marginTop: '10px',
        marginBottom: 0,
        backgroundColor: '#ffffff',
      }}>
      <div className="product-detail-title">찐후기</div>
      <div style={{ marginLeft: '8px' }}>
        <Carousel
          options={{
            dragFree: true,
            align: 'start',
          }}>
          {reviews.map((item: ReviewContent, index: number) => {
            return (
              <div
                key={`review-carousel-item-${index}`}
                className={'embla__slide'}
                style={{
                  flex: `0 0 280px`,
                  border: '1px solid #e8eaed',
                  borderRadius: '12px',
                  marginRight: '8px',
                  marginLeft: '8px',
                }}>
                <div style={{ margin: '20px' }}>
                  <div>
                    <img style={{ width: '22px', height: '16px' }} src={QuotesIcon} />
                  </div>
                  <div
                    onClick={() => {
                      commonApi.getCommunityReview(item._id).then((response) => {
                        const communityReview = response.data;
                        appNavigation.to('/my-page/community', {
                          state: {
                            tab: 'review',
                            reviewId: item._id,
                            position: communityReview.position,
                          },
                        });
                      });
                    }}
                    style={{ marginBottom: '18px', marginTop: '12px', height: '145px', cursor: 'pointer' }}>
                    <div
                      style={{
                        fontSize: '15px',
                        color: '#424242',
                        lineHeight: '24px',
                        marginBottom: '10px',
                        maxHeight: '120px',
                        letterSpacing: -0.4,
                        overflow: 'hidden',
                      }}>
                      {item.content}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ fontSize: '12px', color: '#9b9da0' }}>{item.profileName} 부모님</div>
                      <div style={{ fontSize: '12px', color: '#0180ef', marginLeft: '8px' }}>{item.age}</div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      borderBottom: '1px solid #eff1f4',
                    }}
                  />
                  <div
                    onClick={() => {
                      if (item.productId !== productId) {
                        linkTo(
                          {
                            action: DeepLinkActions.GO_TO_PRODUCT,
                            payload: { productId: item.productId },
                          },
                          { source: 'seller-review-summary', productId: productId },
                        );
                      }
                    }}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginTop: '12px',
                      cursor: 'pointer',
                    }}>
                    <div style={{ width: '200px', height: '44px', marginRight: '12px' }}>
                      <div
                        style={{
                          fontSize: '14px',
                          lineHeight: '19px',
                          color: '#242424',
                          maxHeight: '38px',
                          letterSpacing: -0.4,
                          fontWeight: 'bold',
                          overflow: 'hidden',
                        }}>
                        {item.productName}
                      </div>
                    </div>
                    <img
                      src={cdnImageUrl(item.productImageUrl)}
                      style={{ width: '44px', height: '44px', borderRadius: '6px', objectFit: 'cover' }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};
