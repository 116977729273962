import NeedsCaptionImage from '@assets/images/app/caption-needs@3x.png';
import { ProductModel } from '@models/product';
import React from 'react';

export const ClassPreparations: React.FC<{ data: ProductModel }> = (props) => {
  const data = props.data;
  return (
    <div style={{ marginTop: '80px', paddingLeft: '16px', paddingRight: '16px' }}>
      <img style={{ width: '53px', height: '12px', marginBottom: '10px' }} src={NeedsCaptionImage} />
      <div
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
          lineHeight: '26px',
          letterSpacing: -0.6,
        }}>
        클래스 준비물
      </div>
      <div style={{ marginTop: '16px', whiteSpace: 'pre-wrap' }}>
        {((data.details?.preparationText || '').split('\n') || []).map((line, index) => {
          if (line[0] === '-') {
            line = line.replace('-', '').trim();
          }

          if (line.length === 0) {
            return;
          }

          return (
            <div
              key={`preparation-${index}`}
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '12px',
              }}>
              <div
                style={{
                  alignSelf: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  backgroundColor: '#242424',
                  flexShrink: 0,
                }}>
                <div
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: '#ffffff',
                  }}>
                  {index + 1}
                </div>
              </div>
              <div
                style={{
                  marginLeft: '12px',
                  border: '1px solid #e8eaed',
                  borderRadius: '8px',
                  padding: '16px',
                  width: '100%',
                }}>
                <div
                  style={{
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#424242',
                  }}>
                  {line}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
