import React from 'react';
export interface DefaultUrl {
  url: string;
  [key: string]: any;
}

export const DefaultUrlComponent: React.FC<DefaultUrl> = ({ url, text, ...props }) => (
  <a href={url} {...props} rel="noopener noreferrer" target="_blank">
    {text ? text : url}
  </a>
);
