import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import AppBottomModal from '../common/AppBottomModal';
import LeadToAppStoreImg from '@assets/images/app/lead-to-app-store.png';
import BottomRoundButton from '@components/common/BottomRoundButton';
import ContinueOnWebButton from '@components/common/ContinueOnWebButton';
import { openInNativeApp } from '@utils/common';
import { setHideOpenInAppModal } from '@utils/string';

interface OpenInAppModalProps {
  currentPath: string;
  onClose: () => void;
  open: boolean;
}

export const OpenInAppModal: React.FC<OpenInAppModalProps> = (props) => {
  return (
    <AppBottomModal
      height={500}
      open={props.open}
      onRightNaviButtonClick={() => props.onClose && props.onClose()}
      hideHeader
      headerStyle={{ borderRadius: '20px 20px 0 0' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          whiteSpace: 'pre-wrap',
          padding: '44px 28px 54px',
          height: '100%',
          width: '100%',
          backgroundImage: `url(${LeadToAppStoreImg})`,
          backgroundSize: '320px 320px',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#242424',
        }}>
        <div
          style={{
            fontFamily: 'Pretendard',
            fontSize: '22px',
            fontWeight: 'bold',
            lineHeight: '28px',
            textAlign: 'center',
            color: '#ffffff',
          }}>
          아이고고 앱으로{'\n'}더욱 쉽고 편리하게 만나요
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          <BottomRoundButton
            style={{ zIndex: 100 }}
            onClick={() => {
              const path = props.currentPath;
              if (path) {
                openInNativeApp(path);
              }
            }}>
            <div>앱에서 보기</div>
          </BottomRoundButton>
          <ContinueOnWebButton
            style={{ marginTop: '20px' }}
            onClick={() => {
              setHideOpenInAppModal();
              props.onClose && props.onClose();
            }}>
            <div
              style={{
                color: 'white',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                fontFamily: 'Pretendard',
              }}>
              오늘은 웹에서 볼게요
            </div>
          </ContinueOnWebButton>
        </div>
      </div>
    </AppBottomModal>
  );
};

export default OpenInAppModal;
