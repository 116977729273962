import React from 'react';
import ButtonCloseWhite from '@assets/images/app/btn-close-white.png';
import AppIcon from '@assets/images/app/appIcon.png';
import BottomRoundButton from '@components/common/BottomRoundButton';
import { openInNativeApp } from '@utils/common';

interface OpenInAppBannerProps {
  onClose: () => void;
  currentPath: string;
}

export const OpenInAppBanner: React.FC<OpenInAppBannerProps> = (props) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '64px',
        backgroundColor: '#242424',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '12px 16px 12px 12px',
        zIndex: 99999,
      }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div
          style={{
            width: '32px',
            height: '32px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => {
            props.onClose && props.onClose();
          }}>
          <img src={ButtonCloseWhite} style={{ width: '20xp', height: '20px', marginRight: '10px' }} />
        </div>
        <img src={AppIcon} style={{ width: '40xp', height: '40px', marginRight: '12px' }} />
        <div
          style={{
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontWeight: 'bold',
            lineHeight: '20px',
            color: 'white',
            whiteSpace: 'pre-wrap',
          }}>
          아이고고 앱에서 {'\n'}더 쉽고 편리하게!
        </div>
      </div>
      <BottomRoundButton
        style={{ height: '32px', width: '88px', borderRadius: '6px' }}
        onClick={() => {
          const path = props?.currentPath;
          if (path) {
            openInNativeApp(path);
          }
        }}>
        <div style={{ fontSize: '14px', lineHeight: '20px' }}>앱에서 보기</div>
      </BottomRoundButton>
    </div>
  );
};

export default OpenInAppBanner;
