import { REGEX_URL, DELIMITER, REGEX_BOLD, REGEX_PHONE_NUMBER } from './constants';

export const splitByURL = (text: string) =>
  text.replace(REGEX_URL, (text) => DELIMITER + text + DELIMITER).split(DELIMITER);
export const splitByDoubleQuotes = (text: string) =>
  text.replace(REGEX_BOLD, (text) => DELIMITER + text + DELIMITER).split(DELIMITER);
export const splitByText = (text: string, splitText: string) => {
  const regex = new RegExp(`${splitText}`, 'i');
  return text.replace(regex, (text) => DELIMITER + text + DELIMITER).split(DELIMITER);
};
export const splitByPhoneNumber = (text: string) =>
  text.replace(REGEX_PHONE_NUMBER, (text) => DELIMITER + text + DELIMITER).split(DELIMITER);
