import { ClassFee } from '@models/invoice';
import React, { useEffect, useState } from 'react';
import CellTitle from './CellTitle';
import { getPaymentValues } from '@utils/payment';

export interface ClassFeeViewProps {
  defaultClassFee?: number;
  totalClassTimes?: number;
  totalStudentCount?: number;
  applyGroupDiscount?: boolean;
  usedPoint?: number;
  edit?: boolean;
  onChangeClassFee?: (value: ClassFee) => void;
}

export const ClassFeeView: React.FC<ClassFeeViewProps> = (props) => {
  const [classFee, setClassFee] = useState<number>(0);

  const totalClassTimes = props.totalClassTimes || 0;
  const studentCount = props.totalStudentCount || 0;
  const { totalAmount, discountedAmount, discountAmount, discountPercent } = getPaymentValues({
    classFeePerTimes: classFee,
    totalClassTimes: totalClassTimes,
    totalStudentCount: studentCount,
    applyGroupDiscount: props.applyGroupDiscount,
  });

  useEffect(() => {
    setClassFee(props.defaultClassFee || 0);
  }, [props.defaultClassFee]);

  useEffect(() => {
    props.onChangeClassFee && props.onChangeClassFee({ fee: classFee, total: discountedAmount, regular: totalAmount });
  }, [classFee, discountedAmount, totalAmount]);

  const usedPoint = props.usedPoint || 0;

  return (
    <div style={{ padding: '28px 20px' }}>
      <CellTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>수강료</div>
          {/* <button
              className="link-button"
              style={{
                width: '88px',
                height: '32px',
                borderRadius: '6px',
                border: '1px solid #E8EAED',
                color: 'var(--Neutral-90, #424242)',
                textAlign: 'center',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
              }}>
              수강료 변경
            </button> */}
        </div>
      </CellTitle>
      <div style={{ borderBottom: '1px dashed #E8EAED', paddingBottom: '16px' }}>
        <div style={{ borderLeft: '4px solid #EFF1F4', paddingLeft: '12px', marginTop: '16px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{
                color: 'var(--Neutral-70, #808387)',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
              }}>
              <div style={{ height: props.edit ? '44px' : '22px', marginBottom: '8px' }}>1회 수강료</div>
              <div style={{ height: '22px', marginBottom: '8px' }}>신청 회차</div>
              <div style={{ height: '22px' }}>신청 인원</div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                color: 'var(--Neutral-90, #424242)',
                textAlign: 'right',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
              }}>
              <div style={{ height: props.edit ? '44px' : '22px', marginBottom: '8px' }}>
                {props.edit && (
                  <div
                    style={{
                      height: '44px',
                      borderRadius: '8px',
                      border: '1px solid var(--Neutral-100, #242424)',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      color: 'var(--Primary-95, #FF3D8F)',
                      fontFamily: 'Pretendard',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: '22px',
                      padding: '0 16px',
                    }}>
                    <input
                      value={classFee.toLocaleString()}
                      style={{
                        width: '120px',
                        border: 'none',
                        outline: 'none',
                        textAlign: 'right',
                        color: 'var(--Primary-95, #FF3D8F)',
                        fontFamily: 'Pretendard',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                      }}
                      onChange={(e) => {
                        setClassFee(Number(e.target.value.replace(/[^\d.-]+/g, '')));
                      }}></input>
                    원
                  </div>
                )}
                {!props.edit && `${classFee.toLocaleString()}원`}
              </div>
              <div style={{ height: '22px', marginBottom: '8px' }}>{`${totalClassTimes}회`}</div>
              <div style={{ height: '22px' }}>{`${studentCount || 0}명`}</div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: 'var(--Primary-95, #424242)',
            fontFamily: 'Pretendard',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            marginTop: '16px',
          }}>
          <div>총 금액</div>
          <div
            style={{
              color: 'var(--Neutral-100, #242424)',
              textAlign: 'right',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '22px',
            }}>{`${totalAmount.toLocaleString()}원`}</div>
        </div>
      </div>

      <div style={{ marginTop: '16px' }}>
        <CellTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>결제 금액</div>
          </div>
        </CellTitle>
        <div style={{ borderLeft: '4px solid #EFF1F4', paddingLeft: '12px', marginTop: '16px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{
                color: 'var(--Neutral-70, #808387)',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
              }}>
              <div style={{ height: '22px', marginBottom: '8px' }}>수강료</div>
              {discountAmount > 0 && (
                <div style={{ height: '22px', marginBottom: '8px' }}>{`그룹할인(${discountPercent}%)`}</div>
              )}
              {usedPoint > 0 && <div style={{ height: '22px' }}>포인트</div>}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                color: 'var(--Neutral-90, #424242)',
                textAlign: 'right',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
              }}>
              <div style={{ height: '22px', marginBottom: '8px' }}>{`${totalAmount.toLocaleString()}원`}</div>
              {discountAmount > 0 && (
                <div style={{ height: '22px', marginBottom: '8px' }}>{`-${discountAmount.toLocaleString()}원`}</div>
              )}
              {usedPoint > 0 && <div style={{ height: '22px' }}>{`-${usedPoint.toLocaleString()}원`}</div>}
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: 'var(--Primary-95, #424242)',
            fontFamily: 'Pretendard',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            marginTop: '16px',
          }}>
          <div>최종 결제 금액</div>
          <div
            style={{
              color: 'var(--Neutral-100, #242424)',
              textAlign: 'right',
              fontFamily: 'Pretendard',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '24px',
            }}>{`${(discountedAmount - usedPoint).toLocaleString()}원`}</div>
        </div>
      </div>
    </div>
  );
};

export default ClassFeeView;
