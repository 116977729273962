import { Carousel } from '@components/common/Carousel';
import CheckBox from '@components/common/CheckBox';
import { ClassTimes } from '@models/invoice';
import React, { useEffect, useState } from 'react';
import CellTitle from './CellTitle';

export interface ClassTimesEditProps {
  times?: ClassTimes;
  onChange?: (times: ClassTimes) => void;
}

export const ClassTimesEdit: React.FC<ClassTimesEditProps> = (props) => {
  const [selectedNumberIndex, setSelectedNumberIndex] = useState<number>(0);
  const [classDuration, setClassDuration] = useState<number>(60);
  const [customTime, setCustomTime] = useState<boolean>(false);

  useEffect(() => {
    const newTimes = { totalClassTimes: selectedNumberIndex + 1, duration: classDuration };
    if (JSON.stringify(props.times) !== JSON.stringify(newTimes)) {
      props.onChange && props.onChange(newTimes);
    }
  }, [selectedNumberIndex, classDuration]);

  return (
    <div style={{ padding: '28px 20px' }}>
      <CellTitle>수강 회차</CellTitle>
      <div style={{ display: 'flex', marginTop: '24px', marginBottom: '16px' }}>
        <div
          style={{
            color: 'var(--Neutral-90, #424242)',
            fontFamily: 'Pretendard',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '22px',
          }}>
          총 수강 회차
        </div>
        <div
          style={{
            marginLeft: '4px',
            color: 'var(--Primary-95, #FF3D8F)',
            fontFamily: 'Pretendard',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '22px',
          }}>
          {`${selectedNumberIndex + 1}회`}
        </div>
      </div>
      <Carousel
        options={{
          dragFree: true,
          align: 'start',
        }}>
        {[...Array(20)].map((v, index) => {
          const selected = index === selectedNumberIndex;
          return (
            <button
              key={`number-item-${index}`}
              className="link-button"
              style={{
                flex: '0 0 76px',
                display: 'flex',
                width: '68px',
                height: '36px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '8px',
                color: selected ? 'white' : '#424242',
                border: selected ? 'none' : '1px solid #E8EAED',
                backgroundColor: selected ? '#FF3D8F' : 'white',
                marginRight: '8px',
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedNumberIndex(index);
              }}>{`${index + 1}회`}</button>
          );
        })}
      </Carousel>
      <div style={{ width: '100%', height: '1px', backgroundColor: '#EFF1F4', margin: '24px 0' }}></div>
      <div style={{ display: 'flex', marginTop: '24px', marginBottom: '16px', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              color: 'var(--Neutral-90, #424242)',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '22px',
            }}>
            수업 시간(회당)
          </div>
          <div
            style={{
              marginLeft: '4px',
              color: 'var(--Primary-95, #FF3D8F)',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '22px',
            }}>
            {`${classDuration}분`}
          </div>
        </div>
        <div>
          <CheckBox
            checked={customTime}
            onChecked={(value) => {
              setCustomTime(value);
            }}>
            직접 입력
          </CheckBox>
        </div>
      </div>
      {!customTime && (
        <Carousel
          options={{
            dragFree: true,
            align: 'start',
          }}>
          {[30, 60, 90, 120, 150].map((min, index) => {
            const selected = min === classDuration;
            return (
              <button
                key={`min-item-${index}`}
                className="link-button"
                style={{
                  color: selected ? 'white' : '#424242',
                  flex: '0 0 76px',
                  display: 'flex',
                  width: '68px',
                  height: '36px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '8px',
                  border: selected ? 'none' : '1px solid #E8EAED',
                  backgroundColor: selected ? '#FF3D8F' : 'white',
                  marginRight: '8px',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setClassDuration(min);
                }}>{`${min}분`}</button>
            );
          })}
        </Carousel>
      )}
      {customTime && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '52px',
            border: '1px solid var(--Neutral-40, #D5D8DD)',
            borderRadius: '8px',
            color: 'var(--Neutral-90, #424242)',
            fontFamily: 'Pretendard',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '24px',
            overflow: 'hidden',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '16px',
          }}>
          <input
            type={'number'}
            style={{
              width: '100%',
              border: 'none',
              outline: 'none',
              textAlign: 'right',
              color: 'var(--Neutral-90, #424242)',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '24px',
            }}
            value={classDuration}
            onChange={(e) => {
              setClassDuration(Number(e.target.value));
            }}></input>
          분
        </div>
      )}
    </div>
  );
};

export default ClassTimesEdit;
