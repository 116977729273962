import React from 'react';

export const ContinueOnWebButton: React.FC<{
  style?: any;
  children?: any;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = (props) => {
  return (
    <button
      className="link-button"
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 99999,
        ...props.style,
      }}
      onClick={props.onClick}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {props.children}
      </div>
    </button>
  );
};

export default ContinueOnWebButton;
