import React from 'react';

export const Divider: React.FC<{ style?: any }> = (props) => {
  return (
    <div style={{ width: '100%', padding: '0 16px', ...props.style }}>
      <div style={{ height: '1px', width: '100%', backgroundColor: '#EFF1F4' }}></div>
    </div>
  );
};

export const DividerBold: React.FC<{ style?: any }> = (props) => {
  return <div style={{ height: '8px', width: '100%', backgroundColor: '#EFF1F4', ...props.style }}></div>;
};
