import React from 'react';
import CellTitle from './CellTitle';
import CopyIcon from '@assets/images/app/duplicate.png';
import { toast } from 'react-toastify';

export interface ClassAddressViewProps {
  address?: string;
  addressDetail?: string;
}

export const ClassAddressView: React.FC<ClassAddressViewProps> = (props) => {
  return (
    <div style={{ padding: '28px 20px' }}>
      <CellTitle>수강 장소</CellTitle>
      <div
        style={{
          width: '100%',
          margin: '16px 0',
          color: 'var(--Neutral-90, #424242)',
          fontFamily: 'Pretendard',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '22px',
        }}>
        {props.address}
        {props.addressDetail && ` ${props.addressDetail}`}
      </div>
      {props.addressDetail && (
        <button
          className="link-button"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'var(--Blue-60, #1890FF)',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigator.clipboard.writeText(`${props.address} ${props.addressDetail}`);
            toast.dark('주소를 클립보드에 복사했습니다', {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              pauseOnFocusLoss: false,
              draggable: true,
              progress: undefined,
              bodyStyle: {
                color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                textAlign: 'center',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
              },
              theme: 'dark',
            });
          }}>
          <img src={CopyIcon}></img>
          <div style={{ marginLeft: '2px' }}>주소복사</div>
        </button>
      )}
      {!props.addressDetail && (
        <div
          style={{
            width: '100%',
            height: '44px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#F8F9FC',
            borderRadius: '8px',
            color: 'var(--Neutral-70, #808387)',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
          }}>
          {'상세 주소는 결제가 완료되면 공개 됩니다.'}
        </div>
      )}
    </div>
  );
};

export default ClassAddressView;
