import React, { Fragment } from 'react';
import parse from 'html-react-parser';

import { REGEX_URL, REGEX_PHONE_NUMBER } from './constants';
import { splitByURL, splitByPhoneNumber } from './utils';

import { DefaultUrlComponent } from './DefaultUrl';

const hasUrl = (text: string) => {
  return REGEX_URL.test(text);
};

const linkify = (text: string, Component = DefaultUrlComponent) => {
  const dataArray = splitByURL(text);
  const result = dataArray.map((el, index) => {
    const isUrl = REGEX_URL.test(el);
    if (isUrl) {
      return <Component key={`url${index}`} url={el} />;
    }

    const innerDataArray = splitByPhoneNumber(el);
    if (innerDataArray.length > 0) {
      const returnValue = innerDataArray.map((item, index) => {
        const isPhoneNumber = REGEX_PHONE_NUMBER.test(item);
        if (isPhoneNumber) {
          const phoneNumberLink = `tel:///${el.replace(' ', '').replace('-', '').replace('.', '')}`;
          return <Component key={`inner_phone_number${index}`} url={phoneNumberLink} text={item} />;
        } else {
          return <Fragment key={`inner_text${index}`}>{item}</Fragment>;
        }
      });
      return returnValue;
    }

    return <Fragment key={`text${index}`}>{el}</Fragment>;
  });

  return result;
};

interface LinkifyProps {
  children?: string;
  style?: any;
}

const Linkify: React.FC<LinkifyProps> = ({ style, children }) => {
  const text = `${children}` || '';
  const hasHtml = /<\/?[a-z][\s\S]*>/i.test(text);
  const hasSpecialChar = /&\/?[a-z]*;/i.test(text);
  if (hasHtml || hasSpecialChar) {
    return <div style={style}>{parse(text)}</div>;
  }

  return <div style={style}>{linkify(text)}</div>;
};

export { Linkify, linkify, hasUrl };
