import { ProductModel } from '@models/product';
import React from 'react';

export const ShippingInfo: React.FC<{ data: ProductModel }> = (props) => {
  const data = props.data;
  return (
    <div style={{ padding: '20px 16px' }}>
      <div className="product-detail-title">배송정책</div>
      <div style={{ color: '#66686b', fontSize: '13px', lineHeight: '20px', whiteSpace: 'pre-wrap' }}>
        {data?.shippingPolicyText}
      </div>
    </div>
  );
};
