import { Notice, NoticesResponse } from '@models/notices';
import { ReviewContent } from '@models/review';
import { PagenatedResponse, PhoneValidatorResponse } from '@models/common';
import { apiClient } from '.';
import { CreatePaymentStatementsBody, PaymentStatement, TossPaymentsConfirmResponseBody } from '@models/purchase';
import { CommunityPaymentStatementReview } from '@models/product';
import { UpdatePaymentStatementEventType } from '@hooks/payment';

export const requestPhoneValidate = async (phoneNumber: string) => {
  return apiClient.post<PhoneValidatorResponse>(`/app/v1/phone`, { phoneNumber });
};

export const validatePhone = async (data: { phoneNumber: string; codeNumber: number }) => {
  return apiClient.post<{ resultMessage: string }>(`/app/v1/phone/validation`, {
    data,
  });
};

export const getNotices = async () => {
  return apiClient.get<NoticesResponse>('app/v1/notices');
};

export const getNotice = async (noticeId: string) => {
  return apiClient.get<Notice>(`app/v1/notices/${noticeId}`);
};

export const getReviews = async (page: number, size: number) => {
  return apiClient.get<PagenatedResponse<ReviewContent>>('app/v1/community/reviews', {
    params: { page, size },
  });
};

export const getCommunityReview = async (reviewId: string) => {
  return apiClient.get<CommunityPaymentStatementReview>(`app/v1/community/reviews/${reviewId}`);
};

export const createPaymentStatement = async (data: CreatePaymentStatementsBody) => {
  return apiClient.post<PaymentStatement>(`/app/v1/payment/statement`, {
    data,
  });
};

export const updatePaymentStatement = async (data: {
  data: PaymentStatement;
  event: UpdatePaymentStatementEventType;
}) => {
  return apiClient.put<PaymentStatement>(`/app/v1/payment/statement/${data.data.uuid}`, {
    data,
  });
};

export const getPaymentStatement = async (paymentStatementUuid: string) => {
  return apiClient.get<PaymentStatement>(`/app/v1/payment/statement/${paymentStatementUuid}`);
};

export const updateTossPayments = async (data: TossPaymentsConfirmResponseBody) => {
  return apiClient.post<PaymentStatement>(`/app/v1/payment/callback/toss`, data);
};
