import { ChevronDownIcon, ChevronUpIcon } from '@components/app/common/Icons';
import { Linkify } from '@components/Linkify';
import React, { useEffect, useState } from 'react';
import { useComponentSize } from 'react-use-size';

export const CommerceDetailInformation: React.FC<{ detailHtml: string }> = (props) => {
  const [showExpandButton, setShowExpandButton] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const productDetail = useComponentSize();

  useEffect(() => {
    if (productDetail.height > 1150) {
      setShowExpandButton(true);
    } else if (productDetail.height === 1150 && !showMore) {
      setShowExpandButton(true);
    } else {
      setShowExpandButton(false);
    }
  }, [productDetail.height]);

  return (
    <div>
      <div
        ref={productDetail.ref}
        className={'product-detail-info'}
        style={{ height: !showExpandButton ? 'auto' : showMore ? 'auto' : '1150px', overflow: 'hidden' }}>
        <Linkify>{props.detailHtml}</Linkify>
      </div>
      {showExpandButton && (
        <div style={{ padding: '16px', position: 'relative', width: '100%' }}>
          {!showMore && (
            <div
              style={{
                height: '140px',
                width: '100%',
                background: 'linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0))',
                position: 'absolute',
                top: -140,
                left: 0,
                right: 0,
                zIndex: 99999,
              }}></div>
          )}
          <div
            style={{
              display: 'flex',
              border: '1px solid #FF509A',
              borderRadius: '8px',
              height: '56px',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              marginTop: 0,
              cursor: 'pointer',
            }}
            onClick={() => {
              setShowMore((value) => !value);
            }}>
            <div
              style={{
                color: 'var(--Primary-95, #FF3D8F)',
                textAlign: 'center',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '22px',
              }}>
              {showMore ? '상세정보 접기' : '상세정보 펼치기'}
            </div>
            <div style={{ marginLeft: '8px' }}>
              {showMore ? (
                <ChevronUpIcon color={'#FF509A'} style={{ width: '20px', height: '20px' }} />
              ) : (
                <ChevronDownIcon color={'#FF509A'} style={{ width: '20px', height: '20px' }} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
