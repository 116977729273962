import CalendarIcon from '@assets/images/app/calendar.png';
import ClockIcon from '@assets/images/app/clock.png';
import React, { useEffect, useRef, useState } from 'react';
import { DatePicker, TimePicker } from 'antd';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import moment from 'moment';
import 'dayjs/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR';

dayjs.extend(weekday);
dayjs.extend(localeData);

export interface DateTimeButtonPickerProps {
  value?: Date;
  minDate?: string; // YYYY-MM-DD
  maxDate?: string; // YYYY-MM-DD
  minTime?: string; // HH:mm
  maxTime?: string; // HH:mm
  onChange?: (value: Date) => void;
}

export const DateTimeButtonPicker: React.FC<DateTimeButtonPickerProps> = (props) => {
  const timeRef = useRef<any>();
  const [dateString, setDateString] = useState<string>();
  const [timeString, setTimeString] = useState<string>();
  const [date, setDate] = useState<Date>();

  useEffect(() => {
    const dateStr = dateString || moment(date).format('YYYY-MM-DD');
    const newDate = new Date(Date.parse(`${dateStr} ${timeString || props.minTime || '00:00:00'}`));
    setDate(newDate);

    if (dateString || timeString) {
      props.onChange && props.onChange(newDate);
    }
  }, [dateString, timeString]);

  useEffect(() => {
    setDate(props.value);
  }, [props.value]);

  return (
    <div style={{ display: 'flex', marginTop: '8px', gap: '8px' }}>
      <DatePicker
        locale={locale}
        style={{ flex: 1, height: '52px' }}
        format={'YY. MM. DD(dddd)'}
        placeholder={'날짜'}
        suffixIcon={<img src={CalendarIcon}></img>}
        value={dayjs(date || new Date())}
        onChange={(e) => {
          setDate(e?.toDate());
          setDateString(e?.format('YYYY-MM-DD'));
          if (timeRef.current) {
            timeRef.current.showPicker();
          }
        }}
      />
      <TimePicker
        format={'HH:mm'}
        minuteStep={10}
        value={dayjs(date)}
        style={{ flex: 1, height: '52px' }}
        placeholder={'시간'}
        suffixIcon={<img src={ClockIcon}></img>}
        onChange={(e) => {
          setTimeString(e?.format('HH:mm:00'));
        }}
        onBlur={(e) => {
          const value = e.target.value;
          setTimeString(value + ':00');
        }}
      />
    </div>
  );
};

export default DateTimeButtonPicker;
