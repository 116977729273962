import * as configApi from '@apis/remote-config';
import { ProductModel } from '@models/product';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

export const CommerceRefundPolicy: React.FC<{ data: ProductModel }> = (props) => {
  const data = props.data;

  if (!data?.refundPolicies || data?.refundPolicies.length === 0) {
    return <div></div>;
  }

  return (
    <div style={{ padding: '20px 16px' }}>
      <div className="product-detail-title">교환/환불정책</div>
      <div style={{ color: '#66686b', fontSize: '13px', lineHeight: '20px', whiteSpace: 'pre-wrap' }}>
        {data?.refundPolicies[0]}
      </div>
      <div className="product-detail-subtitle">교환 안내</div>
      <div style={{ color: '#66686b', fontSize: '13px', lineHeight: '20px', whiteSpace: 'pre-wrap' }}>
        {data?.refundPolicies[1]}
      </div>
      <div className="product-detail-subtitle">환불 안내</div>
      <div style={{ color: '#66686b', fontSize: '13px', lineHeight: '20px', whiteSpace: 'pre-wrap' }}>
        {data?.refundPolicies[2]}
      </div>
      <div className="product-detail-subtitle">교환 및 환불 신청 방법</div>
      <div style={{ color: '#66686b', fontSize: '13px', lineHeight: '20px', whiteSpace: 'pre-wrap' }}>
        {data?.refundPolicies[3]}
      </div>
    </div>
  );
};

export const ClassRefundPolicy: React.FC<{ data: ProductModel }> = (props) => {
  const classDefaultTermsQuery = useQuery({
    queryKey: [`class-default-terms`],
    queryFn: () => configApi.getClassTerms(),
  });
  const classDefaultTerms = classDefaultTermsQuery.data?.data;

  const data = props.data;
  const refundPolicies: string[] = (data?.refundPolicyText || classDefaultTerms?.classTermsInfo || '')
    .split('\n')
    .map((line: string) => {
      return line.trim().replace(/^-/, '');
    })
    .filter((line: string) => line.length > 0);

  return (
    <div
      style={{
        marginTop: '32px',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingBottom: 0,
      }}>
      <div
        style={{
          fontSize: '20px',
          fontWeight: 'bold',
          lineHeight: '26px',
          letterSpacing: -0.6,
        }}>
        클래스 환불정책
      </div>
      <div style={{ marginTop: '6px' }}>
        <div
          style={{
            fontSize: '14px',
            lineHeight: '21px',
            fontWeight: 'normal',
            color: '#66686b',
            marginTop: '4px',
            width: '100%',
            letterSpacing: -0.4,
            flexWrap: 'wrap',
          }}>
          튜터님마다 환불정책이 다를 수 있습니다. 채팅상담 시 환불정책을 꼭 확인해주세요.
        </div>
      </div>
      {data?.classTermsText ? (
        <div style={{ whiteSpace: 'pre-wrap' }}>{data?.classTermsText}</div>
      ) : (
        refundPolicies.map((item: string, index) => {
          return (
            <div
              key={`terms-${index}`}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginTop: '8px',
                marginLeft: '4px',
              }}>
              <div
                style={{
                  backgroundColor: '#66686b',
                  borderRadius: '50%',
                  width: '4px',
                  height: '4px',
                  marginRight: '10px',
                  marginTop: '8px',
                }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#424242',
                  lineHeight: '22px',
                  width: '100%',
                  letterSpacing: -0.4,
                  flexWrap: 'wrap',
                }}>
                {item}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};
