import { ReactComponent as HeartOnIcon } from '@assets/images/app/heart-pink.svg';
import { ReactComponent as HeartOffGrayIcon } from '@assets/images/app/heart-gray.svg';
import { ReactComponent as HeartOffWhiteIcon } from '@assets/images/app/heart-white.svg';

import { useAppSocket } from '@hooks/appSocket';
import { useUserFavorites } from '@hooks/userFavorites';
import { useAuthStore } from '@stores/authStore';
import { useAppLoginModal } from '@stores/loginModalStore';
import React from 'react';

interface FavoriteButtonProps {
  productId?: string;
  saleType?: string;
  style?: any;
  theme?: string;
}

export const FavoriteButton: React.FC<FavoriteButtonProps> = (props) => {
  const appSocket = useAppSocket();
  const loginModal = useAppLoginModal();
  const authStore = useAuthStore();
  const { productId, saleType } = props;
  const myFavorite = useUserFavorites();
  const isFavorite = (productId && myFavorite.isFavoriteProduct(productId)) || false;

  return (
    <button
      className="link-button"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '32px',
        height: '32px',
        ...props.style,
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!authStore.user) {
          loginModal.open();
          return;
        }
        if (productId) {
          if (isFavorite) {
            myFavorite.removeMyFavorite(productId);
            appSocket.sendEvent({
              type: 'click',
              data: {
                userId: authStore.user?.id,
                path: location.pathname,
                actionData: {
                  from: location.pathname,
                  actionType: 'favorite-remove',
                  productId: productId,
                  id: 'product-favorite',
                },
              },
            });
          } else {
            const data = { productId, saleType: saleType! };
            myFavorite.addMyFavorite(data);
            appSocket.sendEvent({
              type: 'click',
              data: {
                userId: authStore.user?.id,
                path: location.pathname,
                actionData: {
                  from: location.pathname,
                  actionType: 'favorite-add',
                  productId: productId,
                  id: 'product-favorite',
                },
              },
            });
          }
        }
      }}>
      {isFavorite ? <HeartOnIcon /> : props.theme === 'white' ? <HeartOffWhiteIcon /> : <HeartOffGrayIcon />}
    </button>
  );
};

export default FavoriteButton;
