import { ChildInfo } from '@models/auth';
import { getCurrentAge } from '@utils/string';
import moment from 'moment';
import React from 'react';

interface ChildItemProps {
  data: ChildInfo;
  selectable?: boolean;
  editable?: boolean;
  selected?: boolean;
  onSelect?: (item: ChildInfo) => void;
  onDeselect?: (item: ChildInfo) => void;
  onClickEdit?: (item: ChildInfo) => void;
  onClickDelete?: (item: ChildInfo) => void;
}

export const ChildItem: React.FC<ChildItemProps> = (props) => {
  const item = props.data;

  return (
    <div
      id="child-item"
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: props.selectable ? 'pointer' : 'default',
      }}
      onClick={() => {
        if (props.selected) {
          props.selectable && props.onDeselect && props.onDeselect(item);
        } else {
          props.selectable && props.onSelect && props.onSelect(item);
        }
      }}>
      <div>
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '10px',
            height: '44px',
            borderRadius: '12px',
            border: props.selected ? '1px solid #0c8eff' : '1px solid #e8eaed',
            backgroundColor: '#ffffff',
            padding: '16px',
          }}>
          {item ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    style={{
                      fontSize: '16px',
                      lineHeight: '22px',
                      color: '#424242',
                      fontWeight: 'bold',
                    }}>
                    {item.name} {item.gender === 'm' ? '(남아)' : '(여아)'}
                  </div>
                  <div
                    style={{
                      fontSize: '13px',
                      lineHeight: '22px',
                      color: '#808387',
                      marginLeft: '2px',
                    }}>
                    {item.birthday &&
                      `만 ${getCurrentAge(item.birthday)} 세 / ${moment(item.birthday, 'YYYYMMDD').format(
                        'YYYY.MM.DD',
                      )}`}
                  </div>
                </div>
              </div>

              {props.editable && (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div
                    style={{
                      display: 'flex',
                      border: '1px solid #dadcdf',
                      borderRadius: '4px',
                      height: '30px',
                      width: '42px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: '8px',
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      props.onClickEdit && props.onClickEdit(item);
                    }}>
                    <div
                      style={{
                        color: '#808387',
                        fontSize: '14px',
                        textDecorationLine: 'none',
                      }}>
                      수정
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      border: '1px solid #dadcdf',
                      borderRadius: '4px',
                      height: '30px',
                      width: '42px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      props.onClickDelete && props.onClickDelete(item);
                    }}>
                    <div
                      style={{
                        color: '#808387',
                        fontSize: '14px',
                        textDecorationLine: 'none',
                      }}>
                      삭제
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                width: '100%',
                paddingLeft: '16px',
                paddingRight: '16px',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <div style={{ fontSize: '15px', color: '#9b9b9b' }}>등록된 아이 정보가 없습니다.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
