import { usePayment } from '@hooks/payment';
import { ProductSelectedOption } from '@models/commerce';
import { ProductModel } from '@models/product';
import React from 'react';
import AppBottomModal from '../common/AppBottomModal';
import CommercePayment from './view/CommercePayment';
import CommercePaymentComplete from './view/CommercePaymentComplete';

interface CommercePaymentModalProps {
  open?: boolean;
  product?: ProductModel;
  totalFee: number;
  selectedOptions?: ProductSelectedOption;

  onClickBack?: () => void;
  onConfirm?: () => void;
}

export const CommercePaymentModal: React.FC<CommercePaymentModalProps> = (props) => {
  const payment = usePayment();
  const { open, product, totalFee, selectedOptions, onConfirm } = props;

  return (
    <AppBottomModal
      title={'결제하기'}
      open={open}
      height={0}
      hideRightNaviButton
      onLeftNaviButtonClick={() => {
        props.onClickBack && props.onClickBack();
        payment.clear();
      }}>
      {payment.status === 'done' && payment.paymentStatement && (
        <CommercePaymentComplete
          paymentStatement={payment.paymentStatement}
          onConfirm={() => {
            payment.clear();
            onConfirm && onConfirm();
          }}
        />
      )}
      {payment.status !== 'done' && (
        <CommercePayment
          product={product}
          totalFee={totalFee}
          selectedOptions={selectedOptions}
          onConfirm={onConfirm}></CommercePayment>
      )}
    </AppBottomModal>
  );
};
