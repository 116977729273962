import { AvailableDistrict, ClassAvailableArea, ClassBadge } from '@models/class';

export const removeTempProperties = (data: any) => {
  let newData = data;
  if (Array.isArray(data)) {
    newData = newData.map((item: any) => removeTempProperties(item));
  } else if (data && data.constructor === Object) {
    const replaceData: any = {};
    Object.keys(data).forEach((key) => {
      if (!key.includes('Temp')) {
        replaceData[key] = removeTempProperties(data[key]);
      }
    });
    newData = replaceData;
  }

  return newData;
};

export const getAddressesText = (props: {
  activityType?: string;
  badges?: ClassBadge[];
  availableArea?: ClassAvailableArea;
}) => {
  const { activityType, badges, availableArea } = props;
  let addressText = '-';

  if (activityType && activityType === 'online') {
    addressText = '전체';
  } else {
    if (badges && badges.length > 0 && badges.join(',').includes('ONLINE')) {
      addressText = '전체';
    } else {
      if (availableArea && availableArea.availableDistricts && availableArea.availableDistricts.length > 0) {
        return getAddressesTextFromAvailableDistrict(availableArea.availableDistricts);
      } else if (availableArea && availableArea.specificAddress && availableArea.specificAddress.address) {
        addressText =
          availableArea.specificAddress.address
            .split(' ')[0]
            .replace('특별시', '')
            .replace('광역시', '')
            .replace('', '') +
          ' ' +
          (availableArea.specificAddress.address.split(' ')[1].length > 2
            ? availableArea.specificAddress.address.split(' ')[1].replace(/구$/, '').replace(/시$/, '')
            : availableArea.specificAddress.address.split(' ')[1].replace(/시$/, ''));
      }
    }
  }

  return addressText;
};

export const getAddressText = (props: {
  activityType?: string;
  badges?: ClassBadge[];
  availableArea?: ClassAvailableArea;
}) => {
  const { activityType, badges, availableArea } = props;
  let addressText = '-';

  if (activityType && activityType === 'online') {
    addressText = '전체';
  } else {
    if (badges && badges.length > 0 && badges.join(',').includes('ONLINE')) {
      addressText = '전체';
    } else {
      if (availableArea && availableArea.availableDistricts && availableArea.availableDistricts.length > 0) {
        availableArea.availableDistricts.map((district, index) => {
          if (index == 0) {
            let space = ' ';
            let state = district.state;
            if (state.includes('서울') && state.includes('특별시')) {
              state = state.replace('특별시', '');
              state = state.replace(' ', '');
            }

            if (state.includes('인천') || state.includes('부산') || state.includes('대전') || state.includes('대구')) {
              state = state.replace('광역시', '');
              state = state.replace(' ', '');
            }
            if (state.includes('경기도')) {
              state = state.replace('경기도', '경기');
            }
            if (state.includes('경기')) {
              if (district.city && district.city.length > 2) {
                if (district.city.includes('시흥')) {
                  district.city = district.city.replace('시흥시', '시흥');
                } else {
                  district.city = district.city.replace(/시$/, '');
                }
              }
            }
            if (state.includes('인천') || state.includes('부산') || state.includes('대전') || state.includes('대구')) {
              if (district.city && district.city.length > 2) {
                district.city = district.city.replace('구', '');
                district.city = district.city.replace('군', '');
              }
            }

            if (state.includes('서울')) {
              if (district.city && district.city.includes('전체')) {
                space = ' ';
              } else {
                space = '';
                state = state.replace('서울', '');
              }
            }

            if (state == '전체') {
              addressText = state;
              return;
            }

            addressText =
              state +
              space +
              district.city +
              (availableArea?.availableDistricts && availableArea.availableDistricts.length > 1 ? ' 외' : '');
            return;
          }
        });
      } else if (availableArea && availableArea.specificAddress && availableArea.specificAddress.address) {
        addressText =
          availableArea.specificAddress.address
            .split(' ')[0]
            .replace('특별시', '')
            .replace('광역시', '')
            .replace('', '') +
          ' ' +
          (availableArea.specificAddress.address.split(' ')[1].length > 2
            ? availableArea.specificAddress.address.split(' ')[1].replace(/구$/, '').replace(/시$/, '')
            : availableArea.specificAddress.address.split(' ')[1].replace(/시$/, ''));
      }
    }
  }

  return addressText.replace(/ {2}/g, ' ');
};

export const getAddressesTextFromAvailableDistrict = (availableDistricts?: AvailableDistrict[]): string => {
  const result = (availableDistricts || []).map((item) => {
    const state = item.state
      .replace(/특별시$/, '')
      .replace(/광역시$/, '')
      .replace(/도$/, '');
    const city = (item.city || '').replace(/시$/, '').replace(/군$/, '');

    if (!city || city === '') {
      return undefined;
    }
    if (state === '전체') {
      return state;
    }
    return `${state} ${city}`;
  });

  return result.filter((item) => !!item).join(', ');
};

export const AGE_OPTIONS = [
  { label: '5~7세', value: '1' },
  { label: '8~10세', value: '2' },
  { label: '11~13세', value: '3' },
];

export const getAgeString = (ages?: string[]) => {
  if (!ages || ages.length === 0) {
    return '';
  } else if (ages.length === 1 && ages.indexOf('1') !== -1) {
    return AGE_OPTIONS[0].label;
  } else if (ages.length === 1 && ages.indexOf('2') !== -1) {
    return AGE_OPTIONS[1].label;
  } else if (ages.length === 1 && ages.indexOf('3') !== -1) {
    return AGE_OPTIONS[2].label;
  } else if (ages.length === 2 && ages.indexOf('1') !== -1 && ages.indexOf('2') !== -1) {
    return '4-10세';
  } else if (ages.length === 2 && ages.indexOf('2') !== -1 && ages.indexOf('3') !== -1) {
    return '8-13세';
  } else if (ages.length === 3 || (ages.length === 2 && ages.indexOf('1') !== -1 && ages.indexOf('3') !== -1)) {
    return '4-13세';
  }
  return '';
};

// 뱃지 우선순위: 파랑(이벤트) > 마감(대기접수) > 초록(직접입력 > NEW) > 빨강(직접입력 > 그룹할인)
type BadgeTypes = 'blue' | 'close' | 'green' | 'new' | 'red' | 'discount';
const BADGE_ORDERS = ['blue', 'close', 'green', 'new', 'red', 'discount'];
const BADGE_COLORS = {
  blue: { color: '#0c8eff', backgroundColor: '#e6f3ff' },
  close: { color: '#808387', backgroundColor: '#eff1f4' },
  gray: { color: '#808387', backgroundColor: '#eff1f4' },
  green: { color: '#1fa671', backgroundColor: 'rgba(37, 192, 131, 0.1)' },
  new: { color: '#1fa671', backgroundColor: 'rgba(37, 192, 131, 0.1)' },
  red: { color: '#f03f45', backgroundColor: '#ffeeee' },
  discount: { color: '#f03f45', backgroundColor: '#ffeeee' },
};
export const getBadges = (props: {
  badges: ClassBadge[];
  isNew?: boolean;
  isClosed?: boolean;
  applyGroupDiscount?: boolean;
}) => {
  const { isNew, isClosed, applyGroupDiscount, badges } = props;

  const activeBadges = badges.filter((badge) => badge.isActive);

  if (isNew) {
    activeBadges.push({
      type: 'new',
      isActive: true,
      value: 'NEW',
    });
  }

  if (isClosed) {
    activeBadges.push({
      type: 'close',
      isActive: true,
      value: '대기접수',
    });
  }

  if (applyGroupDiscount) {
    activeBadges.push({
      type: 'discount',
      isActive: true,
      value: '그룹할인',
    });
  }

  return activeBadges
    .map((badge) => {
      const type = badge.type as BadgeTypes;
      const colors = BADGE_COLORS[type];
      return { ...badge, ...colors };
    })
    .sort((lv, rv) => {
      const lvIndex = BADGE_ORDERS.findIndex((item) => item === lv.type);
      const rvIndex = BADGE_ORDERS.findIndex((item) => item === rv.type);
      return lvIndex - rvIndex;
    })
    .slice(0, 2);
};

export const shuffleArray = (data: any[]) => {
  if (!Array.isArray(data)) {
    return data;
  }

  const contents = [...data];
  for (let i = contents.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [contents[i], contents[j]] = [contents[j], contents[i]];
  }
  return contents;
};
