import HighFiveIcon from '@assets/images/app/icon-high-five.png';
import { ChevronDownIcon, ChevronUpIcon, ClassIcon, CommerceIcon } from '@components/app/common/Icons';
import { useAppNavigation } from '@hooks/appNavigation';
import { ProductModel } from '@models/product';
import { APP_BASE_PATH } from '@variables';
import React, { useState } from 'react';

export const ProductSummary: React.FC<{ data: ProductModel; closed?: boolean }> = (props) => {
  const appNavigation = useAppNavigation();
  const [showGroupDiscountInfo, setShowGroupDiscountInfo] = useState<boolean>(false);
  const data = props.data;

  return (
    <div style={{ padding: '24px 16px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <button
          className={'link-button'}
          style={{ cursor: data?.saleType === 'class' ? 'pointer' : 'auto' }}
          onClick={() => {
            if (data?.saleType === 'class') {
              appNavigation.to(`${APP_BASE_PATH}tutor-info/${data?.sellerId}`);
            }
          }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <span
              style={{
                height: '20px',
                marginRight: '4px',
              }}>
              {data.saleType === 'class' ? <ClassIcon /> : <CommerceIcon />}
            </span>
            <span
              style={{
                color: '#424242',
                fontSize: '14px',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                height: '20px',
              }}>
              {data?.sellerName}
            </span>
            <span
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                height: '20px',
                marginLeft: '8px',
                color: 'var(--Neutral-60, #9B9DA0)',
                fontFamily: 'Pretendard',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
              }}>{`조회수 ${(data?.viewCount || 0).toLocaleString()}`}</span>
          </div>
        </button>
      </div>
      <div
        style={{
          marginTop: '12px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flex: '1px',
        }}>
        <div
          style={{
            fontSize: '18px',
            lineHeight: '24px',
            letterSpacing: -0.2,
            color: '#424242',
            flex: '1px',
            flexWrap: 'wrap',
          }}>
          {data?.title}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '14px',
        }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {data?.discount && (
            <div
              style={{
                color: props.closed ? '#B9BBBE' : 'var(--Primary-95, #FF3D8F)',
                fontFamily: 'Pretendard',
                fontSize: '22px',
                fontStyle: 'normal',
                fontWeight: 700,
                marginRight: '4px',
              }}>
              {data?.discount}
            </div>
          )}
          <div
            style={{
              fontFamily: 'Pretendard',
              fontSize: '22px',
              fontWeight: 'bold',
              color: props.closed ? '#B9BBBE' : '#242424',
            }}>
            {(data?.fee || 0).toLocaleString()}
          </div>
          <div
            style={{
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontWeight: 'normal',
              color: props.closed ? '#B9BBBE' : '#242424',
              lineHeight: '26px',
            }}>
            원
            {data?.showRegularTime && data?.regularTime && data?.regularTime > 0 ? ` / ${data?.regularTime}회` : <></>}
          </div>
          {data?.originFee && data.originFee > 0 ? (
            <div
              style={{
                fontFamily: 'Pretendard',
                marginLeft: '4px',
                textDecorationLine: 'line-through',
                color: props.closed ? '#B9BBBE' : '#b9bbbe',
                fontSize: '14px',
              }}>
              {`${data.originFee.toLocaleString()}원`}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {props.closed && (
        <div
          style={{
            marginTop: '12px',
            color: 'var(--Negative-40, #FC4242)',
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 400,
          }}>
          다음 오픈을 기다려주세요.
        </div>
      )}
      {data?.applyGroupDiscount && (
        <div
          style={{
            marginTop: '20px',
            padding: '12px 16px',
            borderRadius: '8px',
            border: '1px solid #e8eaed',
            flexDirection: 'column',
            zIndex: 10,
          }}
          onClick={() => {
            setShowGroupDiscountInfo((value) => !value);
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <div style={{ display: 'flex' }}>
              <img src={HighFiveIcon} style={{ width: '20px', height: '20px', marginRight: '8px' }} />
              <div
                style={{
                  color: 'var(--Neutral-90, #424242)',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                }}>
                그룹할인 혜택 최대 40% 할인
              </div>
            </div>
            {showGroupDiscountInfo ? (
              <ChevronUpIcon style={{ width: '16px', height: '16px' }} />
            ) : (
              <ChevronDownIcon style={{ width: '16px', height: '16px' }} />
            )}
          </div>
          {showGroupDiscountInfo && (
            <div style={{ marginTop: '24px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div
                  style={{
                    width: '4px',
                    height: '4px',
                    backgroundColor: '#b9bbbe',
                    borderRadius: '50px',
                    marginRight: '8px',
                  }}
                />
                <div style={{ display: 'flex', fontFamily: 'Pretendard', fontSize: '14px', color: '#424242' }}>
                  <div style={{ fontWeight: 'bold' }}>2인</div>
                  <div style={{ marginLeft: '4px' }}>그룹 할인</div>
                </div>
                <div
                  style={{
                    marginLeft: '12px',
                    marginRight: '12px',
                    flex: 1,
                    borderBottom: '1px dashed #dadcdf',
                  }}
                />
                <div
                  style={{
                    fontSize: '14px',
                    color: '#FF3D8F',
                    fontWeight: 'bold',
                  }}>
                  20%
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: '8px',
                }}>
                <div
                  style={{
                    width: '4px',
                    height: '4px',
                    backgroundColor: '#b9bbbe',
                    borderRadius: 50,
                    marginRight: '8px',
                  }}
                />
                <div style={{ display: 'flex', fontFamily: 'Pretendard', fontSize: '14px', color: '#424242' }}>
                  <div style={{ fontWeight: 'bold' }}>3인</div>
                  <div style={{ marginLeft: '4px' }}>그룹 할인</div>
                </div>
                <div
                  style={{
                    marginLeft: '12px',
                    marginRight: '12px',
                    flex: 1,
                    borderBottom: '1px dashed #dadcdf',
                  }}
                />
                <div
                  style={{
                    fontSize: '14px',
                    color: '#FF3D8F',
                    fontWeight: 'bold',
                  }}>
                  30%
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: '8px',
                }}>
                <div
                  style={{
                    width: '4px',
                    height: '4px',
                    backgroundColor: '#b9bbbe',
                    borderRadius: '50px',
                    marginRight: '8px',
                  }}
                />
                <div style={{ display: 'flex', fontFamily: 'Pretendard', fontSize: '14px', color: '#424242' }}>
                  <div style={{ fontWeight: 'bold' }}>4인 이상</div>
                  <div style={{ marginLeft: '4px' }}>그룹 할인</div>
                </div>
                <div
                  style={{
                    marginLeft: '12px',
                    marginRight: '12px',
                    flex: 1,
                    borderBottom: '1px dashed #dadcdf',
                  }}
                />
                <div
                  style={{
                    fontSize: '14px',
                    color: '#FF3D8F',
                    fontWeight: 'bold',
                  }}>
                  40%
                </div>
              </div>
              <div
                style={{
                  color: 'var(--Neutral-70, #808387)',
                  textAlign: 'center',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                  padding: '12px 16px',
                  borderRadius: '8px',
                  backgroundColor: '#F8F9FC',
                  marginTop: '20px',
                }}>
                자세한 금액은 상담을 통해 확인해 주세요.
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
