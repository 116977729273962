import * as api from '@apis/app-products';
import { CreateReviewBody, ReviewData, UpdateReviewBody } from '@models/product';
import { ReviewRateType } from '@models/review';
import { Typography } from '@mui/material';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useReviewModalStore } from '@stores/reviewModalStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import AppBottomModal from '../common/AppBottomModal';
import ReactStars from 'react-stars';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export const ReviewModal: React.FC = () => {
  const { show, close, data } = useReviewModalStore();
  const okCancelDialog = useOkCancelDialog();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [reviewData, setReviewData] = useState<ReviewData>({
    rate1: 5,
    rate2: 5,
    rate3: 5,
    rate4: 5,
    comment: '',
  });

  const reviewId = data?.reviewId;
  const paymentStatement = data?.paymentStatement;

  const getReviewQuery = useQuery({
    queryKey: [`review-${reviewId}`],
    queryFn: () => api.getReview(reviewId!),
    enabled: !!reviewId,
  });

  const review = getReviewQuery.data?.data;
  const mutationCreateReviewQuery = useMutation({
    mutationFn: api.createReview,
    onSuccess: async (res) => {
      if (res.status === 201) {
        onSuccess();
      }
    },
  });

  const mutationUpdateReveiwQuery = useMutation({
    mutationFn: api.updateReview,
    onSuccess: async (res) => {
      if (res.status === 200) {
        onSuccess();
      }
    },
  });

  useEffect(() => {
    if (review) {
      setReviewData({ ...review.rates, comment: review.comment });
    }
  }, [review]);

  const onSuccess = () => {
    close();
    data?.onReviewChanged && data.onReviewChanged();
    setReviewData({
      rate1: 5,
      rate2: 5,
      rate3: 5,
      rate4: 5,
      comment: '',
    });

    okCancelDialog.open({
      title: '후기 작성이 완료 되었습니다!',
      content: '',
      type: 'ok',
    });
  };

  const placeHolder =
    '- 후기를 작성해주시면 회차당 1000포인트 적립!\n- 후기는 꼭 15자 이상 작성해주세요.\n- 좋았던 점을 남겨주시면 튜터님께 도움이 돼요👍\n- 아쉬운 점은 채팅방에서 튜터님과 소통해보세요🥰';

  if (!paymentStatement) {
    return <></>;
  }

  return (
    <AppBottomModal
      open={show && !!data}
      title={'후기 작성'}
      footer={<div></div>}
      hideRightNaviButton={true}
      leftNaviButtonIcon={'back'}
      onLeftNaviButtonClick={() => {
        close();
      }}>
      <div
        style={{
          padding: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '16px',
          color: '#66686b',
          textAlign: 'center',
        }}>
        {paymentStatement.product.name || paymentStatement.product.title}
      </div>
      <div className="divider" style={{ height: '10px', width: '100%', backgroundColor: '#f5f7fb' }}></div>

      <OverlayScrollbarsComponent
        defer
        options={{
          scrollbars: { autoHide: 'scroll' },
          overflow: {
            x: 'hidden',
          },
        }}
        style={{
          backgroundColor: '#ffffff',
          marginTop: 0,
          position: 'relative',
          height: 'calc(100dvh - 180px)',
          overflow: 'auto',
          width: '100%',
        }}>
        <div style={{ padding: '30px 0' }}>
          <div>
            {Object.keys(reviewData)
              .filter((key) => {
                return key !== 'comment';
              })
              .map((key, index) => {
                const rateKeys = [
                  ReviewRateType.SOCIABILITY,
                  ReviewRateType.PROFESSIONALISM,
                  ReviewRateType.READINESS,
                  ReviewRateType.PUNCTUALITY,
                ];
                const value = reviewData[key as 'rate1' | 'rate2' | 'rate3' | 'rate4'];

                return (
                  <div
                    key={key}
                    style={{
                      padding: '0 20px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: '10px',
                    }}>
                    <Typography style={{ width: '100px', fontSize: '16px', fontWeight: 'bold' }} component="legend">
                      {rateKeys[index]}
                    </Typography>
                    <ReactStars
                      size={24}
                      value={value}
                      onChange={(value: any) => {
                        setReviewData((prev) => {
                          const newValue = { ...prev } as any;
                          newValue[key] = value;
                          return newValue;
                        });
                      }}
                    />
                  </div>
                );
              })}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ color: '#808387', textAlign: 'center', marginTop: '14px' }}>
              별점은 아이고고만 살짝 볼게요.
              <br />
              솔직하게 남겨주세요.
            </div>
          </div>
        </div>
        <div className="divider" style={{ height: '10px', width: '100%', backgroundColor: '#f5f7fb' }}></div>
        <div style={{ padding: '0 20px', marginBottom: '20px' }}>
          <div
            style={{
              margin: '30px 0 20px',
              fontSize: '16px',
              fontWeight: 'bold',
              color: '#242424',
              textAlign: 'center',
            }}>
            다른 부모님들이 함께 볼 수 있는 후기를 남겨주세요.
          </div>
          <Input.TextArea
            style={{ borderRadius: '12px', fontSize: '14px', padding: '20px', minHeight: '150px' }}
            placeholder={placeHolder}
            value={reviewData.comment}
            onChange={(e) => {
              setReviewData((value) => {
                const newValue = { ...value, comment: e.target.value };
                return newValue;
              });
              if (errorMessage) {
                setErrorMessage('');
              }
            }}
          />
          <div
            style={{
              marginTop: '6px',
              marginLeft: '20px',
              fontSize: '13px',
              lineHeight: '18px',
              color: '#ef4747',
            }}>
            {errorMessage}
          </div>
        </div>
      </OverlayScrollbarsComponent>
      <div
        onClick={() => {
          if (reviewData.comment.length < 15) {
            setErrorMessage('후기는 15자 이상 작성해주세요.');
            return;
          }
          okCancelDialog.open({
            title: review ? '후기를 수정하시겠어요?' : '후기를 등록하시겠어요?',
            content: review ? '변경하신 내용으로 후기가 수정돼요.' : '후기를 등록하시면 포인트가 적립돼요.',
            confirmText: review ? '수정하기' : '등록하기',
            onConfirm: () => {
              if (review) {
                if (paymentStatement._id) {
                  const updateBody: UpdateReviewBody = {
                    ...reviewData,
                    paymentStatementId: paymentStatement._id,
                    productId: paymentStatement.product.id,
                    reviewId: review._id,
                  };
                  mutationUpdateReveiwQuery.mutate(updateBody);
                }
              } else {
                const createBody = {
                  ...reviewData,
                  paymentStatementId: paymentStatement._id,
                  productId: paymentStatement.product.id,
                } as CreateReviewBody;
                mutationCreateReviewQuery.mutate(createBody);
              }
            },
          });
        }}
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          height: '60px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '18px',
          fontWeight: 'bold',
          color: 'white',
          backgroundColor: '#4394f0',
          cursor: 'pointer',
        }}>
        {review ? '후기 수정' : '후기 등록'}
      </div>
    </AppBottomModal>
  );
};
export default ReviewModal;
