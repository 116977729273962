import DeliveryIcon from '@assets/images/app/icon-detail-delivery.png';
import ExperienceIcon from '@assets/images/app/icon-detail-experience.png';
import MegaphoneIcon from '@assets/images/app/icon-detail-megaphone.png';
import { useAppNavigation } from '@hooks/appNavigation';
import { ProductModel } from '@models/product';
import { APP_BASE_PATH } from '@variables';
import React from 'react';

export const CommerceInformation: React.FC<{ data: ProductModel }> = (props) => {
  const appNavigation = useAppNavigation();
  const data = props.data;

  return (
    <div style={{ padding: '28px 16px' }}>
      <div className="product-detail-title">상품 정보</div>
      <div style={{ padding: '20px 0' }}>
        {data?.shippingProduct && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <img style={{ width: '24px', height: '24px', marginRight: '16px' }} src={DeliveryIcon} />
              <div
                style={{
                  fontSize: '18px',
                  lineHeight: '24px',
                  fontWeight: 500,
                  color: '#242424',
                }}>
                택배비
                {!data?.shippingFee ? ` 무료` : ` ${(data?.shippingFee || 0).toLocaleString()}원`}
              </div>
            </div>
            <div style={{ marginLeft: '40px' }}>
              <div
                style={{
                  color: '#808387',
                  fontSize: '14px',
                  lineHeight: '21px',
                  marginTop: '4px',
                  whiteSpace: 'pre-wrap',
                }}>
                {data?.sellerShortComment}
              </div>
            </div>
          </>
        )}
        {!data?.shippingProduct && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: '16px',
              }}>
              <img style={{ width: '24px', height: '24px', marginRight: '16px' }} src={ExperienceIcon} />
              <div
                style={{
                  flex: '1px',
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                <div
                  style={{
                    fontSize: '18px',
                    lineHeight: '24px',
                    fontWeight: 500,
                    color: '#242424',
                    flex: 1,
                  }}>
                  체험수업
                </div>
              </div>
            </div>
            <div style={{ marginLeft: '40px' }}>
              <div
                style={{
                  color: '#808387',
                  fontSize: '14px',
                  lineHeight: '21px',
                  marginTop: '4px',
                  whiteSpace: 'pre-wrap',
                }}>
                {data?.sellerShortComment}
              </div>
            </div>
          </>
        )}
        {/* {data?.location && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '16px',
              marginBottom: '4px',
              width: '100%',
              alignItems: 'flex-start',
            }}>
            <img style={{ width: '24px', height: '24px', marginRight: '16px' }} src={LocationIcon} />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                flex: '1px',
              }}>
              <div>
                <div
                  style={{
                    fontSize: '18px',
                    lineHeight: '24px',
                    fontWeight: 500,
                    color: '#242424',
                    whiteSpace: 'pre-wrap',
                  }}>
                  {getAddressesText({ availableArea: data?.location })}
                </div>

                <div
                  style={{
                    color: '#808387',
                    fontSize: '14px',
                    lineHeight: '21px',
                    marginTop: '4px',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}>
                  {data?.activityType === 'home'
                    ? '출강 지역은 튜터님의 실시간 출강 상황에 따라 변동될 수 있습니다. 상담을 통해 확인해 보세요.'
                    : '상세주소는 상담시 문의해주세요.'}
                </div>
              </div>
            </div>
          </div>
        )} */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '16px',
          }}>
          <img style={{ width: '24px', height: '24px', marginRight: '16px' }} src={MegaphoneIcon} />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>
              <div
                style={{
                  fontSize: '18px',
                  lineHeight: '24px',
                  fontWeight: 500,
                  color: '#242424',
                }}>
                구매 안내
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: '40px' }}>
          <div
            style={{
              color: '#808387',
              fontSize: '14px',
              lineHeight: '21px',
              marginTop: '4px',
              whiteSpace: 'pre-wrap',
            }}>
            {data?.sellerComment}
          </div>
        </div>
      </div>
      {data?.tags && data?.tags.length > 0 && (
        <>
          <div
            style={{
              height: '1px',
              width: '100%',
              backgroundColor: '#f5f7fb',
              marginTop: '20px',
              marginBottom: '20px',
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: '16px',
              flexWrap: 'wrap',
            }}>
            {data?.tags.slice(0, 2).map((item, index) => {
              return (
                <button
                  className="link-button"
                  key={`tag-${index}`}
                  style={{
                    display: 'flex',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    borderRadius: '6px',
                    backgroundColor: '#eff1f4',
                    height: '28px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '8px',
                  }}
                  onClick={() => {
                    appNavigation.to(`${APP_BASE_PATH}search`, {
                      state: {
                        keyword: item,
                      },
                    });
                  }}>
                  <div
                    style={{
                      fontSize: '14px',
                      color: '#808387',
                      fontWeight: 'bold',
                    }}>
                    {item}
                  </div>
                </button>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
