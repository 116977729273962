import { ChildInfo, User } from '@models/auth';
import { ageTextFromBirthday } from '@utils/string';
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import SelectWeekDays from '../common/SelectWeekDays';

interface RequestReopenAlarmModalProps {
  childList?: ChildInfo[];
  open?: boolean;
  productId?: string;
  user?: User | null;
  onCancel?: () => void;
  onConfirm?: (data: any) => void;
}

interface RegisterNotificationData {
  type: string;
  userId?: string;
  userPhone?: string;
  saleType?: 'class' | 'commerce';
  productId?: string;
  weekdays?: string[];
  memo?: string;
}

type RegisterNotificationDataKeys = 'type' | 'userId' | 'userPhone' | 'saleType' | 'productId' | 'weekdays' | 'memo';

export const RequestReopenAlarmModal: React.FC<RequestReopenAlarmModalProps> = (props) => {
  const [registerNotificationData, setRegisterNotificationData] = useState<RegisterNotificationData>({
    type: 'reopen',
  });

  useEffect(() => {
    const user = props.user;
    const productId = props.productId;
    if (productId) {
      const child = user?.profile?.children && user?.profile?.children.length > 0 && user?.profile?.children[0];
      let age = '';
      if (child) {
        age = ageTextFromBirthday(child?.birthday || '');
      }

      setRegisterNotificationData({
        type: 'reopen',
        userId: user?.id,
        userPhone: user?.profile?.phone,
        saleType: 'class',
        productId: productId,
        weekdays: [],
        memo: age,
      });
    }
  }, [props.productId, props.user]);

  const onChangeProperty = (key: RegisterNotificationDataKeys, value: any) => {
    setRegisterNotificationData((prev) => {
      const newProperties = { ...prev };
      newProperties[key] = value;
      return newProperties;
    });
  };

  return (
    <Modal
      onCancel={props.onCancel}
      open={props.open}
      title={'수업 알림 신청하기'}
      footer={
        <button
          className={'link-button'}
          style={{
            display: 'flex',
            width: '100%',
            backgroundColor: '#0c8eff',
            height: '60px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => {
            props.onConfirm && props.onConfirm(registerNotificationData);
          }}>
          <div style={{ color: '#ffffff', fontSize: '18px', textAlign: 'center' }}>요청하기</div>
        </button>
      }
      styles={{
        header: { display: 'flex', justifyContent: 'center' },
        body: { overflow: 'hidden', maxHeight: 'calc(100% - 300px)' },
      }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            marginTop: '12px',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#66686b',
            whiteSpace: 'pre-wrap',
          }}>
          재 오픈시 알림을 받으시려면 정보를 입력해주세요.
        </div>
        <SelectWeekDays
          style={{ marginTop: '20px', marginLeft: 0 }}
          label={'희망 요일 선택'}
          selectedItems={registerNotificationData.weekdays}
          onChange={(weekdays) => {
            onChangeProperty('weekdays', weekdays);
          }}></SelectWeekDays>
        <div
          style={{
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          <div
            style={{
              color: '#242424',
              fontSize: '14px',
              lineHeight: '17px',
              fontWeight: 'bold',
            }}>
            연락처
          </div>
          <input
            style={{
              border: '1px solid #0c8eff',
              height: '48px',
              borderRadius: '8px',
              paddingLeft: '16px',
              paddingRight: '16px',
              marginTop: '8px',
              fontSize: '16px',
              lineHeight: '20px',
              color: '#242424',
            }}
            value={registerNotificationData?.userPhone}
            onChange={(e) => {
              onChangeProperty('userPhone', e.target.value.replace(/[^\d]+/g, '').slice(0, 11));
            }}
            placeholder={'010-0000-0000'}
          />
        </div>
        <div
          style={{
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          <div
            style={{
              color: '#242424',
              fontSize: '14px',
              lineHeight: '17px',
              fontWeight: 'bold',
            }}>
            메모
          </div>
          <input
            style={{
              border: '1px solid #0c8eff',
              height: '48px',
              borderRadius: '8px',
              paddingLeft: '16px',
              paddingRight: '16px',
              marginTop: '8px',
              fontSize: '16px',
              lineHeight: '20px',
              color: '#242424',
            }}
            value={registerNotificationData?.memo}
            onChange={(e) => {
              onChangeProperty('memo', e.target.value);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
