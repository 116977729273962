import TutorDefaultAvatar from '@assets/images/app/tutorintro.png';
import { useAppNavigation } from '@hooks/appNavigation';
import { ProductModel } from '@models/product';
import { cdnImageUrl } from '@utils/image';
import { APP_BASE_PATH } from '@variables';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';

export const TutorInformation: React.FC<{ data: ProductModel }> = (props) => {
  const data = props.data;
  const appNavigation = useAppNavigation();
  if (data.saleType !== 'class') {
    return <></>;
  }

  return (
    <div style={{ paddingLeft: '16px', paddingRight: '16px', marginTop: '32px', paddingBottom: '16px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div className="product-detail-title">
          <div>{data?.sellerName}</div>
          <div>튜터입니다.</div>
        </div>
        <div>
          <img
            style={{
              flexShrink: 0,
              width: '52px',
              height: '52px',
              borderRadius: '35px',
              backgroundColor: '#FFFFFF',
              border: '1px solid rgba(0,0,0,0.04)',
              overflow: 'hidden',
              objectFit: 'cover',
            }}
            src={data?.sellerProfileImage ? cdnImageUrl(data?.sellerProfileImage) : TutorDefaultAvatar}
          />
        </div>
      </div>
      <div
        style={{
          width: '100%',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#424242',
          marginTop: '12px',
          whiteSpace: 'pre-wrap',
        }}>
        <LinesEllipsis
          text={data?.sellerIntroduction || '반갑습니다, 부모님. ' + data?.sellerName + ' 튜터 입니다.'}
          maxLine={5}
          ellipsis="..."
          trimRight></LinesEllipsis>
      </div>
      <button
        className={'link-button'}
        style={{
          display: 'flex',
          borderRadius: '8px',
          marginTop: '16px',
          border: '1px solid #dadcdf',
          alignItems: 'center',
          justifyContent: 'center',
          height: '36px',
          width: '100%',
        }}
        onClick={() => {
          appNavigation.to(`${APP_BASE_PATH}tutor-info/${data?.sellerId}`);
        }}>
        <div style={{ fontSize: '14px', fontWeight: '500', color: '#424242' }}>프로필 보기</div>
      </button>
    </div>
  );
};
