import React, { useEffect, useState } from 'react';
import ArrowDownIcon from '@assets/images/app/icon-arr-down.png';
import ArrowUpIcon from '@assets/images/app/icon-arr-up.png';
export interface OptionSelectProps<T> {
  expand?: boolean;
  label: string;
  options: T[];
  renderOption: (option: T, index: number, selected?: boolean) => any;
  onSelect?: (option: T) => void;
  style?: any;
}

export const OptionSelect = <T extends object | string>(props: OptionSelectProps<T>): React.ReactElement => {
  const { label, options, renderOption, onSelect, style } = props;
  const [expand, setExpand] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    if (props.expand) {
      setExpand(props.expand);
    }
  }, [props.expand]);
  return (
    <div
      style={{
        marginRight: '16px',
        marginLeft: '16px',
        overflow: 'hidden',
        borderRadius: '8px',
        border: expand ? '1px solid #424242' : '1px solid #e8eaed',
        ...style,
      }}>
      <div
        style={{
          backgroundColor: '#ffffff',
          marginLeft: 0,
          marginRight: 0,
        }}>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingLeft: '16px',
              paddingRight: '16px',
              height: '48px',
              cursor: 'pointer',
              backgroundColor: expand ? '#f8f9fc' : 'white',
            }}
            onClick={() => {
              setExpand((value) => !value);
            }}>
            <div
              style={{
                color: '#242424',
                fontSize: '16px',
                lineHeight: '19px',
              }}>
              {label}
            </div>
            <img
              src={expand ? ArrowUpIcon : ArrowDownIcon}
              style={{
                width: '16px',
                height: '16px',
              }}
            />
          </div>
        </div>
        {expand && (
          <div
            style={{
              marginTop: 0,
              position: 'relative',
            }}>
            {(options || []).map((cur: any, index2: number) => {
              return (
                <div
                  key={`option-inner-item-${index2}`}
                  onClick={() => {
                    setSelectedItem((value) => {
                      const newValue = { ...value };

                      if (newValue[`${index2}`]) {
                        delete newValue[`${index2}`];
                      } else {
                        newValue[`${index2}`] = cur;
                        onSelect && onSelect(cur);
                      }
                      return newValue;
                    });
                    setExpand(false);
                  }}>
                  {renderOption(cur, index2, !!selectedItem[`${index2}`])}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
