import HowToCaptionImage from '@assets/images/app/caption-howto@3x.png';
import { ProductModel } from '@models/product';
import { cdnImageUrl } from '@utils/image';
import React from 'react';

export const ClassPreview: React.FC<{ data: ProductModel }> = (props) => {
  const data = props.data;
  return (
    <div style={{ marginTop: '80px', paddingLeft: '16px', paddingRight: '16px' }}>
      <img style={{ width: '64px', height: '12px', marginBottom: '10px' }} src={HowToCaptionImage} />
      <div
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
          lineHeight: '26px',
          letterSpacing: -0.6,
        }}>
        클래스는 이렇게 진행돼요
      </div>

      <div style={{ marginTop: 0 }}>
        {(data.details?.concepts || []).map((item, index) => {
          return (
            <div
              key={`concept-${index}`}
              style={{
                marginTop: index === 0 ? '28px' : '40px',
                display: 'flex',
                flexDirection: 'row',
              }}>
              <div>
                {item.images && item.images.length > 0 && (
                  <div style={{ marginBottom: '20px' }}>
                    {item.images.map((cur, index2) => {
                      return (
                        <div key={`item-image-${index}-${index2}`}>
                          <img
                            style={{
                              width: '100%',
                              marginBottom: item.images!.length - 1 === index2 ? 0 : '4px',
                              objectFit: 'cover',
                            }}
                            src={cdnImageUrl(cur as string)}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}

                {item.conceptTitle && (
                  <div
                    style={{
                      fontSize: '18px',
                      color: '#242424',
                      lineHeight: '24px',
                      fontWeight: 'bold',
                      letterSpacing: -0.2,
                      marginBottom: '12px',
                    }}>
                    {item.conceptTitle}
                  </div>
                )}
                {item.conceptData && (
                  <div
                    style={{
                      width: '100%',
                      flexWrap: 'wrap',
                      marginTop: 0,
                      fontSize: '16px',
                      color: '#424242',
                      lineHeight: '28px',
                      letterSpacing: -0.4,
                      whiteSpace: 'pre-wrap',
                    }}>
                    {item.conceptData.split('\n').map((line, index2) => {
                      if (line && line != '' && line[0] === '-') {
                        line = line.replace('-', '• ');
                      }

                      return (
                        <div key={`concept-data-${index}-${index2}`}>
                          {line}
                          {item.conceptData && item.conceptData.split('\n').length - 1 === index2 ? '' : '\n'}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
